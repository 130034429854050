import {PartnerLicense} from "../model/license/PartnerLicense";
import {Rest} from "./Rest";

export class RestLicense extends Rest {
    list(): Promise<PartnerLicense[]> {
        return this._client.get("/frontend-api/license", PartnerLicense) as Promise<PartnerLicense[]>;
    }

    post(licenseId: number, activate: boolean): Promise<PartnerLicense> {
        return this._client.post(`/frontend-api/license/${licenseId}?activate=${activate}`) as Promise<PartnerLicense>;
    }

    activate(licenseId: number): Promise<PartnerLicense> {
        return this._client.post(`/frontend-api/license/${licenseId}/activate`) as Promise<PartnerLicense>;
    }
}
