import React, {FunctionComponent} from "react";
import {Footer, MainContent as MainContentLib, MainContentStyle} from "@sirdata/ui-lib";
import {MainAlerts, MainModalNotification} from ".";
import {usePortalContext} from "../../context/PortalContext";

export type MainContentProps = {
    children: React.ReactNode;
    style?: MainContentStyle;
    cssClass?: string;
};

const MainContent: FunctionComponent<MainContentProps> = (props) => {
    const {portalSetting} = usePortalContext();

    return (
        <>
            <MainContentLib {...props}/>
            <Footer
                legalNotice={portalSetting.footer.legal_notice}
                cookiesSettings={portalSetting.footer.cookies_settings}
                items={portalSetting.footer.items.map((item) => ({label: item.label, path: item.path}))}
            />
            <MainModalNotification/>
            <MainAlerts/>
        </>
    );
};

export default MainContent;
