import {Serializable} from "../../../common/api/interface/Serializable";

export class CancelInvitationRequest implements Serializable {
    email: string = "";

    toJson(): string {
        return JSON.stringify(this.getJsonParameters());
    }

    getJsonParameters(): {} {
        return {
            email: this.email
        };
    }
}
