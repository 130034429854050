import {ApiModel} from "../ApiModel";
import {PORTAL} from "../../../../utils/Portal";
import {Origin} from "../Origin";

export class ActivateServiceRequest extends ApiModel {
    origin: string = PORTAL.origin.name;
    license_name: string = "";

    constructor(origin?: Origin, licenseName?: string) {
        super(origin);
        if (origin) {
            this.origin = origin.name;
        }
        if (licenseName) {
            this.license_name = licenseName;
        }
    }

    getJsonParameters(): {} {
        return {
            origin: this.origin,
            license_name: this.license_name
        };
    }
}
