import React from "react";
import {session} from "../../api/ApiSession";
import {Origin} from "../../common/api/model/Origin";
import {SvgSirdataAudienceLogoWhite, SvgSirdataLogoWhite} from "@sirdata/ui-lib";

export default function LogoSirdataOnboarding() {

    return (
        session.origin === Origin.AUDIENCE ? <SvgSirdataAudienceLogoWhite/> : <SvgSirdataLogoWhite/>
    );
};
