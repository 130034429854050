import qs from "query-string";

export class QueryString {
    static TOKEN = "token";
    static ORIGIN = "origin";
    static GOTO = "goto";
    static REMEMBER = "remember";
    static REGISTER = "register";

    public static build(params: {}): string {
        return Object.entries(params)
            .filter(([_, value]) => !!value)
            .map(([key, value]) => {
                if (Array.isArray(value)) {
                    return value.map((item) => `${key}=${encodeURIComponent(item as string)}`).join("&");
                }
                return `${key}=${encodeURIComponent(value as string)}`;
            })
            .join("&") || "";
    }

    public static getParam(queryString: string, key: string): string {
        let params = qs.parse(queryString);
        return params[key] as string;
    }

    static getToken(queryString: string): string {
        return this.getParam(queryString, this.TOKEN);
    }

    static getOrigin(queryString: string): string {
        return this.getParam(queryString, this.ORIGIN);
    }

    static getGoTo(queryString: string): string {
        return this.getParam(queryString, this.GOTO);
    }

    static getRemember(queryString: string): boolean {
        return this.getParam(queryString, this.REMEMBER) === "1";
    }

    static getRegister(queryString: string): boolean {
        return this.getParam(queryString, this.REGISTER) === "1";
    }
}
