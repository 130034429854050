import React, {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {PartnerAccount} from "../../../api/model/account/PartnerAccount";
import {session} from "../../../api/ApiSession";
import {EmailInfo} from "../../../api/model/account/EmailInfo";
import getApiErrorTranslationKey from "../../../api/model/ApiErrors";
import {pathConfirmEmail, TranslationPortalFile} from "../../../utils/constants";
import {ButtonUpdate, FormSchema, FormState} from "../../../component/snippet";
import validate from "validate.js";
import {AxiosError} from "axios";
import {Alert, AlertSeverity, ButtonLinkCancel, ButtonSave, FieldBlock, FlexContent, FlexContentAlignment, FlexContentDirection, FlexContentJustify, FormLayoutButtons, FormLayoutMention, FormLayoutRows, InputEmail} from "@sirdata/ui-lib";
import {FormLayoutMessage} from "../../../common/component/snippet";

const FormChangeEmail: FunctionComponent<{ onSuccess: Function }> = ({onSuccess}) => {
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const {t: textCredentials} = useTranslation(TranslationPortalFile.CREDENTIALS);

    const [formState, setFormState] = useState<FormState>({isValid: false, errors: {}});
    const [isShowErrors, setShowErrors] = useState(false);
    const [account, setAccount] = useState<PartnerAccount>();
    const [isOpenForm, setOpenForm] = useState(false);
    const [emailForm, setEmailForm] = useState<{ email: string; confirmEmail: string }>({email: "", confirmEmail: ""});
    const [message, setMessage] = useState("");

    const hasEmptyField = () => !emailForm.email || !emailForm.confirmEmail;

    const handleSubmit = async () => {
        if (!formState.isValid) {
            setShowErrors(true);
            return;
        }
        setShowErrors(false);

        try {
            let emailInfo = new EmailInfo();
            emailInfo.token_link = pathConfirmEmail;

            let account = await session.getPartnerAccount();

            if (account.email === emailForm.email) {
                setMessage(textCredentials("email_form.message.same_email"));
                return;
            }

            account.load({
                email_update: emailForm.email,
                email_info: emailInfo
            });
            await session.restPartnerAccount.update(account);

            setOpenForm(false);
            setEmailForm({email: "", confirmEmail: ""});
            onSuccess(textCredentials("email_form.message.change_email_success"));
        } catch (e) {
            setMessage(t("error." + getApiErrorTranslationKey((e as AxiosError).message), (e as AxiosError).message));
        }
    };

    const closeForm = () => {
        setEmailForm({email: "", confirmEmail: ""});
        setShowErrors(false);
        setOpenForm(false);
    };

    useEffect(() => {
        setMessage("");
        const errors = validate(emailForm, FormSchema);
        setFormState({isValid: !errors, errors: errors || {}});
    }, [emailForm]);

    useEffect(() => {
        (async function () {
            try {
                const account = await session.getPartnerAccount();
                setAccount(account);
            } catch (e) {
                setMessage(t("error.default"));
                setTimeout(() => setMessage(""), 3000);
            }
        })();
    }, [t]);

    return (
        <>
            <FlexContent direction={FlexContentDirection.ROW} alignment={FlexContentAlignment.START}>
                <FieldBlock label={textCredentials("email_form.email")}>
                    {account?.email || ""}
                </FieldBlock>
                <ButtonUpdate onClick={() => setOpenForm(true)} disabled={isOpenForm}/>
            </FlexContent>
            {isOpenForm &&
                <FormLayoutRows>
                    <FieldBlock
                        label={textCredentials("email_form.field.new_email")}
                        content={{direction: FlexContentDirection.COLUMN}}
                        required
                    >
                        <InputEmail
                            placeholder={textCredentials("email_form.placeholder.new_email")}
                            value={emailForm.email}
                            onChange={(value) => setEmailForm((prevState) => ({...prevState, email: value}))}
                            autoFocus
                        />
                        {!!(isShowErrors && formState.errors.email) &&
                            <FormLayoutMessage message={t(`error.${formState.errors.email}`)} severity={AlertSeverity.DANGER}/>
                        }
                    </FieldBlock>
                    <FieldBlock
                        label={textCredentials("email_form.field.confirm_email")}
                        content={{direction: FlexContentDirection.COLUMN}}
                        required
                    >
                        <InputEmail
                            placeholder={textCredentials("email_form.placeholder.confirm_new_email")}
                            value={emailForm.confirmEmail}
                            onChange={(value) => setEmailForm((prevState) => ({...prevState, confirmEmail: value}))}
                        />
                        {!!(isShowErrors && formState.errors.confirmEmail) &&
                            <FormLayoutMessage message={t(`error.${formState.errors.confirmEmail}`)} severity={AlertSeverity.DANGER}/>
                        }
                    </FieldBlock>
                    <FormLayoutMention message={textCredentials("email_form.message.indication_change_email")}/>
                    <FormLayoutButtons justify={FlexContentJustify.END}>
                        <ButtonLinkCancel onClick={closeForm}/>
                        <ButtonSave onClick={handleSubmit} disabled={hasEmptyField()}/>
                    </FormLayoutButtons>
                    {message &&
                        <Alert text={message} severity={AlertSeverity.DANGER}/>
                    }
                </FormLayoutRows>
            }
        </>
    );
};

export default FormChangeEmail;
