import {ApiModel} from "../../../common/api/model/ApiModel";
import {Currency} from "../../../utils/Currency";

export class IncomeItem extends ApiModel {
    month: string = "";
    data_incomes: number = 0;
    minimum_data_incomes: number = 0;
    hash_incomes: number = 0;
    minimum_hash_incomes: number = 0;
    total_incomes: number = 0;
    currency: string = Currency.EURO;
}

export class Income extends ApiModel {
    data: IncomeItem[] = [];

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    load(o: any): void {
        if (typeof o === "object") {
            for (let key in o) {
                const item = new IncomeItem();
                item.load({
                    month: key,
                    data_incomes: o[key].data_incomes / 100,
                    minimum_data_incomes: o[key].minimum_data_incomes / 100,
                    hash_incomes: o[key].hash_incomes / 100,
                    minimum_hash_incomes: o[key].minimum_hash_incomes / 100,
                    total_incomes: o[key].total_incomes / 100,
                    currency: o[key].currency
                });
                this.data.push(item);
            }
        }
    }
}
