import {pathAccess, pathHome, pathSso} from "../../utils/constants";

export class Origin {
    static ACCOUNT: Origin = new Origin("account", "Sirdata Account", "SIRDATA.ACCOUNT", "https://account.sirdata.io");
    static AUDIENCE: Origin = new Origin("audience", "Sirdata Audience", "SIRDATA.AUDIENCE", "https://audience.sirdata.io");
    static CMP: Origin = new Origin("cmp", "Sirdata CMP", "SIRDATA.CMP", "https://cmp.sirdata.io");
    static CONSOLE: Origin = new Origin("console", "Sirdata Console", "SIRDATA.CONSOLE", "https://console.sirdata.io");
    static CUSTOMER: Origin = new Origin("customer", "Sirdata Customer", "SIRDATA.CUSTOMER", "https://customer.sirdata.io");
    static GTM_SERVER_SIDE: Origin = new Origin("gtm_server_side", "Sirdata GTM Server-Side", "SIRDATA.GTM_SERVER_SIDE", "https://sgtm.sirdata.io");
    static HELPER: Origin = new Origin("helper", "Sirdata Helper", "SIRDATA.HELPER", "https://helper.sirdata.io");

    name: string = "";
    productName: string = "";
    service: string = "";
    url: string = "";

    constructor(name: string, productName: string, service: string, url: string) {
        this.name = name;
        this.productName = productName;
        this.service = service;
        this.url = url;
    }

    static values(): Origin[] {
        return [
            Origin.ACCOUNT,
            Origin.AUDIENCE,
            Origin.CMP,
            Origin.CONSOLE,
            Origin.CUSTOMER,
            Origin.GTM_SERVER_SIDE,
            Origin.HELPER
        ];
    }

    static forOrigin(origin?: string): Origin | undefined {
        return this.values().find((it) => it.name === origin?.toLowerCase());
    }

    getUrl(preprod?: boolean): string {
        return preprod ? this.url.replace(".sirdata.io", "-preprod.sirdata.io") : this.url;
    }

    getServiceUrl(preprod?: boolean): string {
        return this.getUrl(preprod) + pathHome;
    }

    getSsoUrl(preprod?: boolean): string {
        return this.getUrl(preprod) + pathSso;
    }

    getAccessUrl(preprod?: boolean): string {
        return this.getUrl(preprod) + pathAccess;
    }

    isInternal(): boolean {
        return this.name === Origin.CONSOLE.name;
    }
}
