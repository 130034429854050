import {Rest} from "../../common/api/rest/Rest";
import {PartnerOrganization} from "../model/account/PartnerOrganization";
import {EmailDomainInfos} from "../model/partner-organization/EmailDomainInfos";

export class RestPartnerOrganization extends Rest {
    create(payload: PartnerOrganization): Promise<PartnerOrganization> {
        return this._client.post("/auth/organization-request", payload, PartnerOrganization) as Promise<PartnerOrganization>;
    }
    getEmailDomainInfos(domain: string): Promise<EmailDomainInfos> {
        return this._client.get(`/auth/domain-organizations?domain=${domain}`, EmailDomainInfos) as Promise<EmailDomainInfos>;
    }
}
