import {ApiModel} from "../ApiModel";

export class AccountBillingStatus extends ApiModel {
    freemium: boolean = false;
    premium: boolean = false;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }
}
