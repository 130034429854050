import {EventEmitter} from "events";
import {AlertSeverity} from "@sirdata/ui-lib";
import {MainAlertItem, MainAlertUIEvent} from "../component/widget/MainAlerts";

export class UIEventManager {
    private static events: EventEmitter = new EventEmitter();

    static emit(event: string, args?: any) {
        this.events.emit(event, args);
    }

    static addListener(event: string, handler: (_?: any) => void) {
        this.events.on(event, handler);
    }

    static removeAllListeners(event: string) {
        this.events.removeAllListeners(event);
    }

    static alert(...alert: [any, AlertSeverity] | [MainAlertItem]) {
        let message: MainAlertItem;
        if (alert.length === 2) {
            const [text, severity] = alert;
            message = {text: text as string, severity: severity};
        } else {
            [message] = alert;
        }
        this.emit(MainAlertUIEvent, message);
    }
}
