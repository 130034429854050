import {ApiModel} from "../ApiModel";
import {EmailInfo} from "./EmailInfo";

enum ContactRequestField {
    EMAIL_INFO = "email_info",
    TOPIC = "topic",
    MESSAGE = "message",
    TAGS = "tags",
}

export class ContactRequest extends ApiModel {
    email_info: EmailInfo = new EmailInfo();
    topic: string = "";
    message: string = "";
    tags: string[] = [];

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    static create(topic: string, message: string, tags: string[]): ContactRequest {
        return new ContactRequest({
            [ContactRequestField.TOPIC]: topic,
            [ContactRequestField.MESSAGE]: message,
            [ContactRequestField.TAGS]: tags
        });
    }

    getJsonParameters(): {} {
        return {
            email_info: this.email_info,
            topic: this.topic,
            message: this.message,
            tags: this.tags
        };
    }
}
