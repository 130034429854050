export enum StatPeriod {
    WEEK = "WEEK",
    MONTH = "MONTH",
    TRIMESTER = "TRIMESTER",
    YEAR = "YEAR",
    CUSTOM = "CUSTOM"
}

export const STAT_PERIODS = [
    StatPeriod.WEEK,
    StatPeriod.MONTH,
    StatPeriod.TRIMESTER,
    StatPeriod.YEAR,
    StatPeriod.CUSTOM
];
