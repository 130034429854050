import {ApiModel} from "../../../common/api/model/ApiModel";

export class AccountInvitation extends ApiModel {
    email: string = "";
    sent_by: string = "";
    creation: string = "";
    expiration: string = "";

    toJson(): string {
        return JSON.stringify(this.getJsonParameters());
    }

    getJsonParameters(): {} {
        return {
            email: this.email,
            sent_by: this.sent_by,
            creation: this.creation,
            expiration: this.expiration
        };
    }
}
