export enum UserNotificationType {
    DEFAULT = "DEFAULT",
    MODAL = "MODAL",
    SLIDER = "SLIDER"
}

export const NOTIFICATION_TYPES = [
    UserNotificationType.DEFAULT,
    UserNotificationType.MODAL,
    UserNotificationType.SLIDER
];
