import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {pathError404} from "../../utils/constants";
import {session} from "../../../api/ApiSession";
import {SirdataApiEvent} from "../../api/CommonApiClient";

const ApiEventListener = () => {
    const navigate = useNavigate();

    useEffect(() => {
        session.on(SirdataApiEvent.eventNotFound, () => {
            navigate(pathError404, {replace: true});
        });
    }, [navigate]);

    return <></>;
};

export default ApiEventListener;
