import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import moment from "moment";
import {session} from "../../api/ApiSession";
import {MainContent, Wrapper} from "../../common/component/widget";
import {Box, BoxRadius, Color, ContentBlock, InputDate, LayoutRows, Loadable, MainContentPageHeader, MainContentStyle, SearchField, SearchToolbar, Select, Table, TableColumn, TableColumnStyle, TableRow} from "@sirdata/ui-lib";
import {StatItem} from "../../utils/StatItem";
import {TranslationPortalFile} from "../../utils/constants";
import {STAT_PERIODS, StatPeriod} from "../../utils/StatPeriod";
import {GraphData} from "../../utils/chart_options";
import {Formatter} from "../../common/utils/Formatter";
import {Interval} from "../../utils/Interval";
import {ButtonDownload} from "../../component/snippet";
import {MainHeader} from "../../common/component/snippet";
import {usePortalContext} from "../../common/context/PortalContext";
import {ChartContainer, ChartLine} from "../../component/widget";

const DATE_FORMAT = "YYYY-MM-DD";
const START_DATE = moment().subtract(1, "month").format(DATE_FORMAT);
const END_DATE = moment().subtract(1, "day").format(DATE_FORMAT);
const ROWS_PER_PAGE = 10;

function TrafficDaily() {
    const {t: textTrafficDaily} = useTranslation(TranslationPortalFile.TRAFFIC_DAILY);
    const {portalSetting} = usePortalContext();
    const [isLoading, setLoading] = useState(true);
    const [partnerId, setPartnerId] = useState(0);
    const [period, setPeriod] = useState<StatPeriod>(StatPeriod.MONTH);
    const [statInterval, setStatInterval] = useState<Interval>({start: START_DATE, end: END_DATE});
    const [currentSite, setCurrentSite] = useState<number>(0);

    const [sites, setSites] = useState<{ value: number; label: string }[]>([]);
    const [stats, setStats] = useState<StatItem[]>([]);
    const [chartData, setChartData] = useState<GraphData>();

    const handleChangeSite = (value: number) => {
        setCurrentSite(value);
        setLoading(true);
    };

    const handleChangeInterval = (name: string, value: string) => {
        setStatInterval((prevState) => ({...prevState, [name]: value}));
        setLoading(true);
    };

    const handleChangePeriod = (value: StatPeriod) => {
        setPeriod(value);
        let start = "";
        switch (value) {
            case StatPeriod.CUSTOM:
            case StatPeriod.WEEK:
                start = moment(END_DATE).subtract(1, "week").format(DATE_FORMAT);
                break;
            case StatPeriod.MONTH:
                start = moment(END_DATE).subtract(30, "days").format(DATE_FORMAT);
                break;
            case StatPeriod.TRIMESTER:
                start = moment(END_DATE).subtract(3, "month").format(DATE_FORMAT);
                break;
            case StatPeriod.YEAR:
                start = moment(END_DATE).subtract(1, "year").format(DATE_FORMAT);
                break;
        }
        setStatInterval({start: start, end: END_DATE});
        setLoading(true);
    };

    const handleDownload = () => {
        const headers = [
            textTrafficDaily("table.date"),
            textTrafficDaily("table.count_view"),
            textTrafficDaily("table.count_md5")
        ];

        let rows = stats.map((stat) => [stat.date, stat.count_view, stat.count_md5].join(";"));
        rows.unshift(headers.join(";"));

        let universalBOM = "\uFEFF";
        let csvArray = universalBOM + rows.join("\r\n");
        let a = document.createElement("a");
        let blob = new Blob([csvArray], {type: "text/csv;charset=utf-8"});

        let url = window.URL.createObjectURL(blob);

        a.href = url;
        a.download = `Sirdata_Statistics_volumes_${statInterval.start}_${statInterval.end}${currentSite ? "_" + sites.find((it) => it.value === currentSite)?.label : ""}.csv`;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
    };

    useEffect(() => {
        if (!partnerId || !isLoading) return;

        const buildGraph = (source: StatItem[]) => {
            const labels: string[] = [];
            const dataViews: number[] = [];
            const dataHash: number[] = [];

            for (let stat of source) {
                labels.push(stat.date);
                dataViews.push(stat.count_view);
                dataHash.push(stat.count_md5);
            }

            setChartData({
                labels: labels, datasets: [
                    {
                        label: textTrafficDaily("table.count_view"),
                        data: dataViews,
                        backgroundColor: "rgb(58, 91, 230, .1)",
                        borderWidth: 1,
                        fill: "origin"
                    },
                    {
                        label: textTrafficDaily("table.count_md5"),
                        data: dataHash,
                        backgroundColor: "rgb(253, 233, 181, .5)",
                        borderWidth: 1,
                        fill: "origin"
                    }
                ]
            });
        };

        (async () => {
            try {
                const result = await session.restPartner.getStats(Formatter.formatDate(statInterval.start, DATE_FORMAT), Formatter.formatDate(statInterval.end, DATE_FORMAT), currentSite);
                let fullStats: StatItem[] = result.views.map((stat) => ({date: stat.date, count_view: stat.count, count_md5: 0}));
                if (fullStats) {
                    for (let stat of result.hashmails) {
                        const index = fullStats.findIndex((it) => it.date === stat.date);
                        if (index === -1) {
                            fullStats.push({date: stat.date, count_view: 0, count_md5: stat.count});
                        } else fullStats[index].count_md5 = stat.count;
                    }
                    fullStats = fullStats.sort((a, b) => a.date <= b.date ? 1 : -1);
                    setStats(fullStats);
                    buildGraph(fullStats);
                }
            } catch (e) {
            } finally {
                setLoading(false);
            }
        })();
    }, [statInterval, currentSite, partnerId, textTrafficDaily, isLoading]);

    useEffect(() => {
        (async function () {
            try {
                const account = await session.getPartnerAccount();
                if (!account.partner.id) return;
                setPartnerId(account.partner.id);

                const resultSites = await session.restPartner.getSites();
                setSites([{value: 0, label: textTrafficDaily("all_sites")}, ...resultSites.map((it) => ({value: it.site_id, label: it.name}))]);
            } catch (e) {
            }
        })();
    }, [textTrafficDaily]);

    return (
        <Wrapper>
            <MainHeader/>
            <MainContent style={MainContentStyle.NARROW_WIDTH}>
                <MainContentPageHeader
                    title={textTrafficDaily("title")}
                    icon={{name: "route", colorIcon: Color.OCEAN, outlined: true}}
                    description={textTrafficDaily("description")}
                />
                <LayoutRows>
                    <ContentBlock header={{title: {label: textTrafficDaily("matching_volumes")}}}>
                        <SearchToolbar themeWhite={portalSetting.hasThemeWhite()}>
                            {(sites.length > 1) &&
                                <SearchField label={textTrafficDaily("filter.site")}>
                                    <Select
                                        options={sites}
                                        value={currentSite}
                                        onChange={(option) => option && handleChangeSite(+option.value)}
                                    />
                                </SearchField>
                            }
                            <SearchField label={textTrafficDaily("filter.period")}>
                                <Select
                                    options={STAT_PERIODS.map((it) => ({value: it, label: textTrafficDaily(`period.${it}`)}))}
                                    value={period}
                                    onChange={(option) => handleChangePeriod(option?.value as StatPeriod)}
                                />
                            </SearchField>
                            {period === StatPeriod.CUSTOM &&
                                <>
                                    <SearchField label={textTrafficDaily("filter.start_date")}>
                                        <InputDate value={statInterval.start} onChange={(value) => handleChangeInterval("start", value)}/>
                                    </SearchField>
                                    <SearchField label={textTrafficDaily("filter.end_date")}>
                                        <InputDate value={statInterval.end} onChange={(value) => handleChangeInterval("end", value)}/>
                                    </SearchField>
                                </>
                            }
                        </SearchToolbar>
                        <Loadable loading={isLoading}>
                            <Box radius={BoxRadius.MD}>
                                {!!stats?.length ?
                                    <ChartContainer height="35rem">
                                        <ChartLine data={chartData}/>
                                    </ChartContainer> :
                                    <span>{textTrafficDaily("no_volumes")}</span>
                                }
                            </Box>
                        </Loadable>
                    </ContentBlock>
                    <Loadable loading={isLoading}>
                        {!!stats?.length &&
                            <ContentBlock header={{actions: [<ButtonDownload key={"download-button"} onClick={handleDownload}/>]}}>
                                <Table
                                    columns={[
                                        {width: 33, label: textTrafficDaily("table.date"), styles: TableColumnStyle.ALIGN_CENTER},
                                        {width: 33, label: textTrafficDaily("table.count_view"), styles: TableColumnStyle.ALIGN_CENTER},
                                        {width: 33, label: textTrafficDaily("table.count_md5"), styles: TableColumnStyle.ALIGN_CENTER}
                                    ]}
                                    pagination={ROWS_PER_PAGE}
                                    themeWhite={portalSetting.hasThemeWhite()}
                                >
                                    {stats.map((stat) =>
                                        <TableRow key={stat.date}>
                                            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{Formatter.formatDate(stat.date)}</TableColumn>
                                            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{Formatter.formatNumber(stat.count_view)}</TableColumn>
                                            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{Formatter.formatNumber(stat.count_md5)}</TableColumn>
                                        </TableRow>
                                    )}
                                </Table>
                            </ContentBlock>
                        }
                    </Loadable>
                </LayoutRows>
            </MainContent>
        </Wrapper>
    );
}

export default TrafficDaily;
