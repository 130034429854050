import React, {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../utils/constants";
import {session} from "../../api/ApiSession";
import {GraphData, optionsLinesMonthly} from "../../utils/chart_options";
import {Box, BoxRadius, ContentBlock, Loadable, Table, TableColumn, TableColumnStyle, TableRow} from "@sirdata/ui-lib";
import {StatItem} from "../../utils/StatItem";
import {Formatter} from "../../common/utils/Formatter";
import {ButtonDownload} from "./index";
import {usePortalContext} from "../../common/context/PortalContext";
import {ChartContainer, ChartLine} from "../widget";

const IncomeVolumesSection: FunctionComponent<{ partnerId: number }> = ({partnerId}) => {
    const {t: textTrafficGlobal} = useTranslation(TranslationPortalFile.TRAFFIC_GLOBAL);
    const {portalSetting} = usePortalContext();
    const [isLoading, setLoading] = useState(true);
    const [stats, setStats] = useState<StatItem[]>();
    const [chartData, setChartData] = useState<GraphData>();

    useEffect(() => {
        const buildGraph = (source: StatItem[]) => {
            const labels: string[] = [];
            const dataHash: number[] = [];

            for (let stat of source) {
                labels.push(stat.date);
                dataHash.push(stat.count_md5);
            }

            setChartData({
                labels: labels, datasets: [
                    {
                        label: textTrafficGlobal("table.count_md5"),
                        data: dataHash,
                        backgroundColor: "rgb(253, 233, 181, .5)",
                        borderWidth: 1,
                        fill: "origin"
                    }
                ]
            });
        };

        (async function () {
            try {
                const result = await session.restPartner.getStatsMonthly();
                let fullStats: StatItem[] = result.hashmails.map((item) => ({date: item.date, count_view: 0, count_md5: item.count}));
                if (fullStats) {
                    fullStats = fullStats.sort((a, b) => a.date <= b.date ? 1 : -1);
                    setStats(fullStats);
                    buildGraph(fullStats);
                }
            } catch (e) {
            } finally {
                setLoading(false);
            }
        })();
    }, [partnerId, textTrafficGlobal]);

    const handleDownload = () => {
        if (!stats) return;

        const headers = [
            textTrafficGlobal("table.month"),
            textTrafficGlobal("table.count_md5")
        ];

        let rows = stats.map((stat) => [stat.date, stat.count_md5].join(";"));
        rows.unshift(headers.join(";"));

        let universalBOM = "\uFEFF";
        let csvArray = universalBOM + rows.join("\r\n");
        let a = document.createElement("a");
        let blob = new Blob([csvArray], {type: "text/csv;charset=utf-8"});

        let url = window.URL.createObjectURL(blob);

        a.href = url;
        a.download = "Sirdata_Monthly_MD5_Volumes.csv";
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
    };

    return (
        <>
            <Loadable loading={isLoading}>
                <ContentBlock header={{title: {label: textTrafficGlobal("matching_volumes")}}}>
                    <Box radius={BoxRadius.MD}>
                        {!!stats?.length ?
                            <ChartContainer height="35rem">
                                <ChartLine data={chartData} options={optionsLinesMonthly}/>
                            </ChartContainer> :
                            <span>{textTrafficGlobal("no_volumes")}</span>
                        }
                    </Box>
                </ContentBlock>
            </Loadable>
            <Loadable loading={isLoading}>
                {!!stats?.length &&
                    <ContentBlock header={{actions: [<ButtonDownload key={"download-button"} onClick={handleDownload}/>]}}>
                        <Table
                            columns={[
                                {width: 25, label: textTrafficGlobal("table.month"), styles: TableColumnStyle.ALIGN_CENTER},
                                {width: 25, label: textTrafficGlobal("table.volumes"), styles: TableColumnStyle.ALIGN_CENTER}
                            ]}
                            themeWhite={portalSetting.hasThemeWhite()}
                        >
                            {stats.map((stat) =>
                                <TableRow key={stat.date}>
                                    <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{Formatter.formatDate(stat.date, "MM/YYYY")}</TableColumn>
                                    <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{Formatter.formatNumber(stat.count_md5)}</TableColumn>
                                </TableRow>
                            )}
                        </Table>
                    </ContentBlock>
                }
            </Loadable>
        </>
    );
};

export default IncomeVolumesSection;
