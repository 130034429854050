import numeral from "numeral";
import moment, {Moment, MomentInput} from "moment";
import {Locale} from "./Locale";

// Numeral French config
numeral.register("locale", "fr", {
    delimiters: {
        thousands: " ",
        decimal: ","
    },
    abbreviations: {
        thousand: "k",
        million: "m",
        billion: "b",
        trillion: "t"
    },
    ordinal: function (number) {
        return number === 1 ? "er" : "ème";
    },
    currency: {
        symbol: "€"
    }
});

export class Formatter {
    static DATE_FORMAT = "DD/MM/YYYY";
    static DATETIME_FORMAT = "DD/MM/YYYY HH:mm";
    static API_DATE_FORMAT = "YYYY-MM-DD";
    static API_DATETIME_FORMAT = "YYYY-MM-DDTHH:mm:ss.SS";
    static FILE_DATE_FORMAT = "YYYYMMDD";
    static FILE_DATETIME_FORMAT = "YYYYMMDDHHmm";

    static formatNumber(value: number | undefined, format?: string): string {
        if (value === undefined) return "";
        numeral.locale(Locale.getCurrentLocale() as string);
        return numeral(value).format(format || "0,0.[00]a").toUpperCase();
    }

    static formatDate(value?: MomentInput, format?: string): string {
        format = format || this.DATE_FORMAT;
        return moment(value).format(format);
    }

    static formatUTCDate(value?: MomentInput, format?: string): string {
        format = format || this.DATE_FORMAT;
        return this.convertUTCToDate(value).format(format);
    }

    static convertDateToUTC(value?: MomentInput): string {
        return moment(value).utc().format();
    }

    static convertUTCToDate(value?: MomentInput): Moment {
        return moment.utc(value).local();
    }

    static convertCentsToUnits(value: number): number {
        return value / 100;
    }

    static convertUnitsToCents(value: number): number {
        return Math.round(value * 100);
    }

    /**
     * Rounds a number up to the next largest integer divisible by `step`.
     * Ex: If value = 143 and step = 5, returns 145. If value = 143 and step = 2, it returns 144
     *
     * @param value
     * @param step
     */
    static roundNumber(value: number, step: number): number {
        if (step === 0) return value;
        return Math.ceil(value / step) * step;
    }

    /**
     * Format bytes with units
     *
     * @param {number} bytes - Value to format (ex: 10000)
     * @returns {string} Return 10 K[b|o]
     */
    static formatBytes(bytes: number): string {
        if (bytes === 0) {
            return "0";
        }

        const units = {
            [Locale.ENGLISH]: ["bytes", "kb", "Mb", "Gb", "Tb"],
            [Locale.FRENCH]: ["octets", "ko", "Mo", "Go", "To"]
        };
        const unitIndex = Math.floor(Math.log(bytes) / Math.log(1000));

        const formattedValue = parseFloat((bytes / Math.pow(1000, unitIndex)).toFixed(2));
        const unit = units[Locale.getCurrentLocale() as string][unitIndex];
        return `${formattedValue} ${unit}`;
    }
}
