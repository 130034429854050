import {PORTAL} from "../../utils/Portal";
import {ApiModel} from "../api/model/ApiModel";
import {QueryString} from "../api/model/QueryString";

export const usePreprod = window.location.origin.includes("-preprod.sirdata.io") || window.location.origin.includes("localhost");

export const goToLogin = (redirectTo?: string) => {
    const queryString = [
        {key: QueryString.ORIGIN, value: PORTAL.origin.name},
        {key: QueryString.GOTO, value: redirectTo || window.location.pathname}
    ].map(({key, value}) => `${key}=${value}`).join("&");

    const accountRoot = (usePreprod ? "https://account-preprod.sirdata.io" : "https://account.sirdata.io");
    window.location.href = `${accountRoot}/login?${queryString}`;
};

export const sortItems = <T extends { [key: string]: any }>(items: T[], field: string, reverseOrder: boolean = false): T[] => {
    return [...items].sort((a, b) => (reverseOrder ? b[field] > a[field] : a[field] > b[field]) ? 1 : -1);
};

export const sortNumbers = (items: number[], reverseOrder: boolean = false): number[] => {
    return [...items].sort((a, b) => (reverseOrder ? b > a : a > b) ? 1 : -1);
};

export const copyApiModelArray = <M extends ApiModel>(model: new () => M, arr: Array<M>): Array<M> => {
    const list = new Array<M>();
    for (let item of arr) {
        let o = new model();
        o.load(item);
        list.push(o);
    }
    return list;
};
