import {Locale} from "../../../utils/Locale";
import {PORTAL} from "../../../../utils/Portal";
import {ApiModel} from "../ApiModel";

export class EmailInfo extends ApiModel {
    origin: string = PORTAL.origin.name;
    locale: Locale = Locale.getCurrentLocale();

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }
}
