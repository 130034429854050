import {useTranslation} from "react-i18next";
import {MainContent, Wrapper} from "../../common/component/widget";
import {Color, LayoutRows, MainContentPageHeader, MainContentStyle} from "@sirdata/ui-lib";
import {FormChangeBillingInfo} from "../../component/snippet";
import {TranslationPortalFile} from "../../utils/constants";
import {MainHeader} from "../../common/component/snippet";

function BillingInfo() {
    const {t: textBillingInfo} = useTranslation(TranslationPortalFile.BILLING_INFO);

    return (
        <Wrapper>
            <MainHeader/>
            <MainContent style={MainContentStyle.NARROW_WIDTH}>
                <MainContentPageHeader
                    title={textBillingInfo("title")}
                    icon={{name: "receipt_long", colorIcon: Color.OCEAN, outlined: true}}
                />
                <LayoutRows>
                    <FormChangeBillingInfo/>
                </LayoutRows>
            </MainContent>
        </Wrapper>
    );
}

export default BillingInfo;
