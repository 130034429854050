import {Account} from "../model/account/Account";
import {ActivateServiceRequest} from "../model/account/ActivateServiceRequest";
import {LoggedAccount} from "../interface/LoggedAccount";
import {Origin} from "../model/Origin";
import {PartnerAuthTmpToken} from "../model/account/PartnerAuthTmpToken";
import {Rest} from "./Rest";

export class RestAccount extends Rest {
    rootPath = "/frontend-api/account";

    get(): Promise<LoggedAccount> {
        return this._client.get(this.rootPath, Account) as Promise<Account>;
    }

    activateService(origin?: Origin, licenseName?: string): Promise<void> {
        return this._client.post(`${this.rootPath}/activate-service`, new ActivateServiceRequest(origin, licenseName)) as Promise<void>;
    }

    getTmpAuthToken(): Promise<PartnerAuthTmpToken> {
        return this._client.get(`${this.rootPath}/sso-token`, PartnerAuthTmpToken) as Promise<PartnerAuthTmpToken>;
    }
}
