import {Locale} from "../../../common/utils/Locale";
import {PORTAL} from "../../../utils/Portal";

export class EmailInfo {
    origin: string = PORTAL.origin.name;
    locale: Locale = Locale.getCurrentLocale();
    token_link?: string; // if set, a validation email is sent to the partner
    replacements: { [key: string]: string } = {};

    setReplacement(key: string, value: string) {
        this.replacements[key] = value;
    }
}
