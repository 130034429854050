import React, {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import clsx from "clsx";
import {UserNotification} from "../../api/model/notification/UserNotification";
import {Action, ActionsMenu, Icon} from "@sirdata/ui-lib";
import {session} from "../../../api/ApiSession";
import {useNavigate} from "react-router-dom";
import {TranslationCommonFile} from "../../utils/constants";
import {Formatter} from "../../utils/Formatter";

export type NotificationMenuItemProps = {
    notification: UserNotification;
    onUpdate: () => void;
}

const MainHeaderNotificationMenuItem: FunctionComponent<NotificationMenuItemProps> = ({notification, onUpdate}) => {
    const {t: textMain} = useTranslation(TranslationCommonFile.MAIN);
    const navigate = useNavigate();
    const MAX_NOTIFICATION_TEXT_LENGTH = 150;

    const [isShowActions, setShowActions] = useState<boolean>();
    const [isRead, setRead] = useState<boolean>();

    useEffect(() => {
        setRead(notification.is_read);
    }, [notification]);

    const handleClick = async () => {
        try {
            if (!isRead) {
                await session.restUserNotifications.markAsRead(notification.id);
                setRead(true);
                await onUpdate();
            }
            if (notification.content.url) {
                if (notification.content.isUrlExternal()) {
                    window.open(notification.content.url, "_blank");
                } else {
                    navigate(notification.content.getUrlPathname());
                }
            }
        } catch {
        }
    };

    const handleToggleRead = async () => {
        try {
            if (isRead) {
                await session.restUserNotifications.markAsUnread(notification.id);
                setRead(false);
            } else {
                await session.restUserNotifications.markAsRead(notification.id);
                setRead(true);
            }
            await onUpdate();
        } catch {
        }
    };

    const handleRemove = async () => {
        try {
            await session.restUserNotifications.remove(notification.id);
            await onUpdate();
        } catch {
        }
    };

    return (
        <li
            className={clsx("main-header__notification-menu__item", {"main-header__notification-menu__item--unread": !isRead})}
            onMouseEnter={() => setShowActions(true)}
            onMouseLeave={() => {
                setShowActions(false);
            }}
        >
            <div className="main-header__notification-menu__item__content" onClick={handleClick}>
                <div className="main-header__notification-menu__item__content__text">
                    {notification.content.text.substring(0, MAX_NOTIFICATION_TEXT_LENGTH)}
                </div>
                <div className="main-header__notification-menu__item__content__info">
                    {Formatter.formatDate(notification.dispatch_date)}
                    {!isRead && <Icon name={"circle"} cssClass="main-header__notification-menu__item__content__info__chip"/>}
                </div>
            </div>
            {isShowActions &&
                <div className="main-header__notification-menu__item__actions">
                    <ActionsMenu
                        iconTooltip={{
                            icon: {...Action.MORE.icon, cssClass: "main-header__notification-menu__item__actions__icon"},
                            text: textMain("menu.notification.options")
                        }}
                        items={[
                            {label: textMain(isRead ? "menu.notification.mark_as_unread" : "menu.notification.mark_as_read"), onClick: handleToggleRead},
                            {label: textMain("menu.notification.remove"), onClick: handleRemove}
                        ]}
                    />
                </div>
            }
        </li>
    );
};

export default MainHeaderNotificationMenuItem;
