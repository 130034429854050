import React, {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../../api/ApiSession";
import {QueryString} from "../../api/model/QueryString";
import {Color, SvgSirdataChip} from "@sirdata/ui-lib";
import clsx from "clsx";
import {Origin} from "../../api/model/Origin";
import {TranslationCommonFile} from "../../utils/constants";
import {PORTAL} from "../../../utils/Portal";
import {usePreprod} from "../../utils/helper";
import {UIEventManager} from "../../utils/UIEventManager";

export type MainHeaderOriginMenuProps = {
    activeOrigins: Origin[];
};

const MainHeaderOriginMenu: FunctionComponent<MainHeaderOriginMenuProps> = ({activeOrigins}) => {
    const {t: textMain} = useTranslation(TranslationCommonFile.MAIN);
    const [isActive, setActive] = useState<boolean>(false);
    const [onMouseLeave, setOnMouseLeave] = useState<() => void>(() => {});

    const goToOrigin = (origin: Origin) => {
        if (origin === PORTAL.origin) {
            window.location.href = PORTAL.defaultPath;
            return;
        }

        (async () => {
            try {
                if (origin === Origin.ACCOUNT) {
                    window.open(origin.getServiceUrl(usePreprod), "_blank");
                } else {
                    const authToken = await session.restAccount.getTmpAuthToken();
                    window.open(`${origin.getSsoUrl(usePreprod)}?${QueryString.TOKEN}=${authToken.token}`, "_blank");
                }
            } catch (e) {
            }
        })();
    };

    const handleMouseLeave = () => {
        setActive(false);
        onMouseLeave();
    };

    useEffect(() => {
        UIEventManager.addListener(MainHeaderOriginMenuUiEvent, (onMouseLeave: () => void) => {
            setActive(true);
            setOnMouseLeave(() => onMouseLeave);
        });
        return () => UIEventManager.removeAllListeners(MainHeaderOriginMenuUiEvent);
    }, []);

    return (
        <div className={clsx("main-header__origin-menu", {"main-header__origin-menu--active": isActive})} onMouseLeave={handleMouseLeave}>
            <div className="main-header__origin-menu__overlay"/>
            <div className="main-header__origin-menu__content">
                <div className="main-header__origin-menu__content__items">
                    {Origin.values().filter((origin) => !origin.isInternal()).map((origin) =>
                        <div className="main-header__origin-menu__content__item" onClick={() => goToOrigin(origin)} key={origin.name}>
                            <SvgSirdataChip currentColor={activeOrigins.find((it) => it.name === origin.name) ? Color.GREEN : Color.GREY_DARK}/>
                            <span className="main-header__origin-menu__content__item__name">{textMain(`menu.origins.${origin.name}`)}</span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default MainHeaderOriginMenu;
export const MainHeaderOriginMenuUiEvent = "MainHeaderOriginMenu";
