import {ApiModel} from "./ApiModel";

export class Country extends ApiModel {
    id: number = 0;
    name: string = "";
    isocode: string = "";

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }
}

export enum CountryIsoCode {
    BELGIUM = "BE",
    FRANCE = "FR",
    UNITED_KINGDOM = "GB"
}

export class CountryCode {
    id: number = 0;
    code: CountryIsoCode;
    name: string;
    flag: string;

    static BELGIUM: CountryCode = new CountryCode(CountryIsoCode.BELGIUM, "Belgium", "belgium.png");
    static FRANCE: CountryCode = new CountryCode(CountryIsoCode.FRANCE, "France", "france.png").withDatabaseId(64);
    static UNITED_KINGDOM: CountryCode = new CountryCode(CountryIsoCode.UNITED_KINGDOM, "United Kingdom", "united-kingdom.png");

    static values(): CountryCode[] {
        return [
            CountryCode.BELGIUM,
            CountryCode.FRANCE,
            CountryCode.UNITED_KINGDOM
        ];
    }

    static getCountryFromCode(country: CountryIsoCode): CountryCode | undefined {
        return this.values().find((it) => it.code === country);
    }

    constructor(code: CountryIsoCode, name: string, flag: string) {
        this.code = code;
        this.name = name;
        this.flag = flag;
    }

    withDatabaseId(id: number): CountryCode {
        this.id = id;
        return this;
    }

    get flagUrl(): string {
        return `/common/images/flags/${this.flag}`;
    }
}
