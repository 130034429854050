import {Serializable} from "../../../common/api/interface/Serializable";
import {EmailInfo} from "./EmailInfo";
import {PORTAL} from "../../../utils/Portal";

export class AccountRequest implements Serializable {
    email: string = "";
    password: string = "";
    email_info: EmailInfo = new EmailInfo();
    creation_service: string = PORTAL.origin.service;
    user: string | undefined;

    toJson(): string {
        return JSON.stringify(this.getJsonParameters());
    }

    getJsonParameters(): {} {
        return {
            email: this.email,
            password: this.password,
            email_info: this.email_info,
            creation_service: this.creation_service,
            user: this.user
        };
    }
}
