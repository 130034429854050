import {Model} from "../../interface/Model";
import {ApiModel} from "../ApiModel";

export class PartnerAuthToken extends ApiModel implements Model {
    auth_token: string = "";

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }
}
