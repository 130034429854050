import React, {FunctionComponent, useEffect, useState} from "react";
import {Button, ButtonLinkCancel, ButtonSave, ButtonSize, ButtonStyle, FieldBlock, FormLayoutButtons, FormLayoutRows, InputText, ModalContent, ModalNew, ModalTitle, Textarea} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";

import {ApiToken} from "../../api/model/ApiToken";
import {TranslationPortalFile} from "../../utils/constants";

export type ModalHandleApiTokenProps = {
    apiToken?: ApiToken;
    active: boolean;
    onClose: () => void;
    onSubmit: (apiToken: ApiToken) => void;
};

const ModalHandleApiToken: FunctionComponent<ModalHandleApiTokenProps> = ({apiToken, onClose, active, onSubmit}) => {
    const {t: textAccessApiTokens} = useTranslation(TranslationPortalFile.ACCESS_API_TOKENS);

    const [name, setName] = useState<string>("");
    const [description, setDescription] = useState<string>("");

    const submit = () => {
        if (!apiToken)
            apiToken = new ApiToken();

        apiToken.load({name: name, description: description});
        onSubmit(apiToken);
    };

    useEffect(() => {
        setName(apiToken?.name || "");
        setDescription(apiToken?.description || "");
    }, [apiToken, active]);

    return (
        <ModalNew onClose={onClose} active={active}>
            <ModalContent>
                <ModalTitle>{textAccessApiTokens(apiToken?.id ? "modal.create.title_edit" : "modal.create.title")}</ModalTitle>
                <FormLayoutRows>
                    <FieldBlock label={textAccessApiTokens("modal.create.field.name")}>
                        <InputText
                            placeholder={textAccessApiTokens("modal.create.placeholder.name")}
                            value={name}
                            onChange={setName}
                        />
                    </FieldBlock>
                    <FieldBlock label={textAccessApiTokens("modal.create.field.description")}>
                        <Textarea
                            placeholder={textAccessApiTokens("modal.create.placeholder.description")}
                            value={description}
                            onChange={setDescription}
                            rows={10}
                        />
                    </FieldBlock>
                    <FormLayoutButtons>
                        <ButtonLinkCancel onClick={onClose}/>
                        {
                            apiToken?.id ?
                                <ButtonSave size={ButtonSize.BIG} onClick={submit}/> :
                                <Button size={ButtonSize.BIG} style={ButtonStyle.PRIMARY_GREEN} onClick={submit}>
                                    {textAccessApiTokens("modal.button.generate")}
                                </Button>
                        }
                    </FormLayoutButtons>
                </FormLayoutRows>
            </ModalContent>
        </ModalNew>
    );
};

export default ModalHandleApiToken;
