import {ApiModel} from "../../../common/api/model/ApiModel";
import {Currency} from "../../../utils/Currency";
import {Invoice} from "./Invoice";

export class MonthlyInvoices extends ApiModel {
    currency: Currency = Currency.EURO;
    invoices: Invoice[] = [];
    month: string = "";
    total_amount: number = 0;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }
}
