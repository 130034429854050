import clsx from "clsx";
import {ApiModel} from "../ApiModel";
import {LoggedAccount} from "../../interface/LoggedAccount";
import {Origin} from "../Origin";
import {PORTAL} from "../../../../utils/Portal";
import {AccountType} from "./AccountType";
import {AccountBillingStatus} from "./AccountBillingStatus";

export class Account extends ApiModel implements LoggedAccount {
    _id: number = 0;
    email: string = "";
    first_name: string = "";
    last_name: string = "";
    type: AccountType = AccountType.USER;
    created_at: string = "";
    authorizations: string[] = [];
    partner_id: number = 0;
    is_user: boolean = false;
    billing_status: AccountBillingStatus = new AccountBillingStatus();
    services: string[] = [];

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    set id(id: number) {
        this._id = id;
    }

    get id(): number {
        return this.partner_id;
    }

    get fullName(): string {
        if (this.first_name || this.last_name) {
            return clsx(this.first_name, this.last_name);
        }
        return "";
    }

    get initials(): string {
        return this.first_name.substring(0, 1) + this.last_name.substring(0, 1);
    }

    get createdAt(): string {
        return this.created_at;
    }

    isUser(): boolean {
        return this.is_user;
    }

    hasAccess(origin?: string): boolean {
        const o = Origin.forOrigin(origin);
        if (!o) return false;
        if (o === Origin.ACCOUNT) return true;
        return !!this.authorizations.find((it) => it === `${o.service}.ACCESS`);
    }

    hasAuthorization(authorization: string): boolean {
        if (!authorization) return true;
        return !!this.authorizations.find((it) => it === `${PORTAL.origin.service}.${authorization}`);
    }

    hasAnyOfAuthorizations(authorizations?: string[]): boolean {
        if (!authorizations || !authorizations.length) return true;
        return !!this.authorizations.find((it) => authorizations.map((it) => `${PORTAL.origin.service}.${it}`).includes(it));
    }

    hasActiveService(origin?: string): boolean {
        const o = Origin.forOrigin(origin);
        if (!o) return false;
        if (o === Origin.ACCOUNT) return true;
        return !!this.services.find((it) => it === `${o.service}`);
    }
}
