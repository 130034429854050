import React, {useEffect, useState} from "react";
import {Navigate} from "react-router-dom";
import {AccountType} from "../../common/api/model/account/AccountType";
import {Authorization} from "../../api/model/account/Authorization";
import {LoggedAccount} from "../../common/api/interface/LoggedAccount";
import {pathLogin} from "../../common/utils/constants";
import {PORTAL} from "../../utils/Portal";
import {session} from "../../api/ApiSession";

type PrivateRouteProps = {
    children: JSX.Element;
    requiredAuthorization?: Authorization;
    restrictedAccountType?: AccountType;
}

const PrivateRoute: React.FunctionComponent<PrivateRouteProps> = ({children, requiredAuthorization, restrictedAccountType}) => {
    const [account, setAccount] = useState<LoggedAccount>();
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                const isLogged = await session.isLogged();
                if (!isLogged) {
                    return;
                }

                const account = await session.getAccount();
                if (!account) {
                    return;
                }

                if (restrictedAccountType && account.type === restrictedAccountType) {
                    return;
                }

                setAccount(account);
            } catch (e) {
            } finally {
                setLoaded(true);
            }
        })();
    }, [restrictedAccountType]);

    if (!loaded) return <></>;

    if (!account) return <Navigate to={pathLogin}/>;

    if (requiredAuthorization && !account.hasAuthorization(requiredAuthorization)) {
        return <Navigate to={PORTAL.defaultPath}/>;
    }

    return children;
};

export default PrivateRoute;
