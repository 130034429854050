import React from "react";
import {PortalSetting} from "../api/model/portal-setting/PortalSetting";

type PortalContext = {
    portalSetting: PortalSetting;
};

const portalContext = React.createContext<PortalContext | undefined>(undefined);

type PortalContextProviderProps = {
    portalSetting: PortalSetting;
    children: React.ReactNode;
};

function PortalContextProvider(props: PortalContextProviderProps) {
    return (
        <portalContext.Provider value={{portalSetting: new PortalSetting(props.portalSetting)}}>
            {props.children}
        </portalContext.Provider>
    );
}

function usePortalContext() {
    const context = React.useContext(portalContext);
    if (context === undefined) {
        throw new Error("usePortalContext must be used within a PortalContextProvider");
    }
    return context;
}

export {PortalContextProvider, usePortalContext};
