export const pathForgottenPassword = "/forgotten-password";
export const pathResetPassword = "/reset-password";
export const pathRegister = "/register";
export const pathRegisterInfo = "/register-info";
export const pathRegisterOrigin = "/register-origin";
export const pathRegisterBilling = "/register-billing";
export const pathInvitationAccept = "/invitation-accept";
export const pathConfirmEmail = "/confirm-email";
export const pathTermsOfUse = "/terms-of-use";

export const pathAccess = "/access";
export const pathAccessAccounts = pathAccess + "/accounts";
export const pathAccessApiTokens = pathAccess + "/api-tokens";
export const pathBilling = "/billing";
export const pathBillingInfo = pathBilling + "/info";
export const pathBillingInvoices = pathBilling + "/invoices";
export const pathCredentials = "/credentials";
export const pathInformation = "/information";
export const pathTraffic = "/traffic";
export const pathTrafficGlobal = pathTraffic + "/global";
export const pathTrafficDaily = pathTraffic + "/daily";

export const pathActivateService = "/activate-service";

export const monthFormat = "YYYY-MM";

/* TRANSLATIONS */
export enum TranslationPortalFile {
    ACCESS_ACCOUNTS = "access-accounts",
    ACCESS_API_TOKENS = "access-api-tokens",
    BILLING_INFO = "billing-info",
    BILLING_INVOICES = "billing-invoices",
    CREDENTIALS = "credentials",
    HOME = "home",
    INFORMATION = "information",
    LEGAL_NOTICE = "legal-notice",
    ONBOARDING = "onboarding",
    TERMS_OF_USE = "terms-of-use",
    TRAFFIC_DAILY = "traffic-daily",
    TRAFFIC_GLOBAL = "traffic-global",
    TRANSLATION = "translation",
}

export const TRANSLATION_PORTAL_FILES = [
    TranslationPortalFile.ACCESS_ACCOUNTS,
    TranslationPortalFile.ACCESS_API_TOKENS,
    TranslationPortalFile.BILLING_INFO,
    TranslationPortalFile.BILLING_INVOICES,
    TranslationPortalFile.CREDENTIALS,
    TranslationPortalFile.HOME,
    TranslationPortalFile.INFORMATION,
    TranslationPortalFile.LEGAL_NOTICE,
    TranslationPortalFile.ONBOARDING,
    TranslationPortalFile.TERMS_OF_USE,
    TranslationPortalFile.TRAFFIC_DAILY,
    TranslationPortalFile.TRAFFIC_GLOBAL,
    TranslationPortalFile.TRANSLATION
];
