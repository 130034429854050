import {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../api/ApiSession";
import {ApiToken} from "../../api/model/ApiToken";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import ModalHandleApiToken from "../../component/modal/ModalHandleApiToken";
import ModalShowApiToken from "../../component/modal/ModalShowApiToken";
import {TranslationPortalFile} from "../../utils/constants";
import {ApiTokenRow} from "../../component/snippet";
import {Action, AlertSeverity, ButtonAdd, ButtonSize, ButtonStyle, Color, ContentBlock, LayoutRows, Loadable, MainContentPageHeader, MainContentStyle, ModalConfirmMessage, Table, TableColumnStyle, TranslationLibFile} from "@sirdata/ui-lib";
import {MainContent, Wrapper} from "../../common/component/widget";
import {MainHeader} from "../../common/component/snippet";
import {UIEventManager} from "../../common/utils/UIEventManager";
import {usePortalContext} from "../../common/context/PortalContext";

function ApiTokens() {
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textAccessApiTokens} = useTranslation(TranslationPortalFile.ACCESS_API_TOKENS);
    const {portalSetting} = usePortalContext();
    const [isLoading, setLoading] = useState(true);
    const [apiTokens, setApiTokens] = useState<ApiToken[]>([]);
    const [currentApiToken, setCurrentApiToken] = useState<ApiToken>();
    const [currentToken, setCurrentToken] = useState<string>("");

    const [activeDeleteTokenId, setActiveDeleteTokenId] = useState<number>();
    const [isShowNewTokenModal, setShowNewTokenModal] = useState(false);

    const handleApiToken = async (apiToken: ApiToken) => {
        setShowNewTokenModal(false);

        try {
            if (apiToken.id) {
                await session.restApiToken.update(apiToken);
                setCurrentApiToken(undefined);
            } else {
                const createdToken = await session.restApiToken.create(apiToken);
                if (createdToken.token) {
                    setCurrentToken(createdToken.token);
                }
            }
            await loadApiTokens();
        } catch (e) {
            if (e instanceof ErrorResponse) {
                UIEventManager.alert(t("error." + e.message.replaceAll(" ", "_"), t("error.default")), AlertSeverity.DANGER);
            }
        }
    };

    const deleteApiToken = async (id?: number) => {
        if (!id) return;
        setActiveDeleteTokenId(undefined);

        try {
            await session.restApiToken.delete(id);
            await loadApiTokens();
        } catch (e) {
            if (e instanceof ErrorResponse) {
                UIEventManager.alert(t("error." + e.message.replaceAll(" ", "_"), t("error.default")), AlertSeverity.DANGER);
            }
        }
    };

    const loadApiTokens = useCallback( async () => {
        try {
            setLoading(true);
            const tokens = await session.restApiToken.list();
            setApiTokens(tokens);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                UIEventManager.alert(t("error." + e.message.replaceAll(" ", "_"), t("error.default")), AlertSeverity.DANGER);
            }
        } finally {
            setLoading(false);
        }
    }, [t]);

    useEffect(() => {
        (async function () {
            await loadApiTokens();
        })();
    }, [loadApiTokens]);

    return (
        <Wrapper>
            <MainHeader/>
            <MainContent style={MainContentStyle.NARROW_WIDTH}>
                <MainContentPageHeader
                    title={textAccessApiTokens("title")}
                    icon={{name: "manage_accounts", colorIcon: Color.OCEAN, outlined: true}}
                />
                <LayoutRows>
                    <Loadable loading={isLoading}>
                        <ContentBlock
                            header={{
                                actions: [
                                    <ButtonAdd
                                        key={"add-button"}
                                        size={ButtonSize.MEDIUM}
                                        onClick={() => setShowNewTokenModal(true)}
                                    />
                                ]
                            }}
                        >
                            <Table
                                columns={[
                                    {width: 30, label: textAccessApiTokens("table.array_header.name")},
                                    {width: 45, label: textAccessApiTokens("table.array_header.description"), styles: TableColumnStyle.HIDE_SCREEN_MEDIUM},
                                    {width: 20, label: textAccessApiTokens("table.array_header.created_at"), styles: TableColumnStyle.HIDE_SCREEN_MEDIUM},
                                    {width: 5, label: textAccessApiTokens("table.array_header.actions"), styles: TableColumnStyle.ALIGN_CENTER}
                                ]}
                                themeWhite={portalSetting.hasThemeWhite()}
                            >
                                {apiTokens.map((item) =>
                                    <ApiTokenRow
                                        key={item.id}
                                        apiToken={item}
                                        onUpdate={() => {
                                            setCurrentApiToken(item);
                                            setShowNewTokenModal(true);
                                        }}
                                        onDelete={() => setActiveDeleteTokenId(item.id)}
                                    />
                                )}
                            </Table>
                        </ContentBlock>
                    </Loadable>
                </LayoutRows>
                <ModalHandleApiToken
                    apiToken={currentApiToken}
                    active={isShowNewTokenModal}
                    onSubmit={handleApiToken}
                    onClose={() => {
                        setShowNewTokenModal(false);
                        setCurrentApiToken(undefined);
                    }}
                />
                <ModalShowApiToken token={currentToken} active={!!currentToken} onClose={() => setCurrentToken("")}/>
                <ModalConfirmMessage
                    active={!!activeDeleteTokenId}
                    cancel={{onClick: () => setActiveDeleteTokenId(undefined), style: ButtonStyle.PRIMARY_MIDNIGHT}}
                    confirm={{onClick: () => deleteApiToken(activeDeleteTokenId), style: ButtonStyle.DEFAULT_MIDNIGHT, children: textCommon(Action.DELETE.labelKey)}}
                    message={textAccessApiTokens("table.action.delete_warning")}
                />
            </MainContent>
        </Wrapper>
    );
}

export default ApiTokens;
