import {ApiModel} from "../ApiModel";

export class License extends ApiModel {
    name: string = "";
    description?: string;
    info?: Map<string, any>;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }
}
