import React, {FormEvent, useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import validate from "validate.js";

import {session} from "../../api/ApiSession";
import getApiErrorTranslationKey from "../../api/model/ApiErrors";
import {pathRegister, TranslationPortalFile} from "../../utils/constants";
import {pathLogin} from "../../common/utils/constants";
import {AxiosError} from "axios";
import {Alert, AlertSeverity, Box, BoxSpacing, Button, ButtonSize, FieldBlock, FlexContent, FlexContentAlignment, FlexContentDirection, FlexContentSpacing, Form, FormLayoutButtons, FormLayoutRows, FormValidationType, InputPassword, Paragraph, ParagraphAlignment} from "@sirdata/ui-lib";
import {FormSchema, FormState, LogoSirdataOnboarding} from "../../component/snippet";
import {FormLayoutMessage} from "../../common/component/snippet";
import {QueryString} from "../../common/api/model/QueryString";

function InvitationAccept() {
    const location = useLocation();
    const navigate = useNavigate();
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const {t: textOnboarding} = useTranslation(TranslationPortalFile.ONBOARDING);

    const [token, setToken] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [formState, setFormState] = useState<FormState>({isValid: false, errors: {}});
    const [passwordForm, setPasswordForm] = useState({password: "", confirmPassword: ""});
    const [apiError, setApiError] = useState("");
    const [isShowErrors, setShowErrors] = useState(false);
    const [message, setMessage] = useState("");

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();

        if (!formState.isValid) {
            setShowErrors(true);
            return;
        }
        setLoading(true);
        try {
            await session.restInvitation.accept(token, passwordForm.password);
            setMessage(textOnboarding("form.message.account_created"));
            setSubmitted(true);
        } catch (e) {
            setApiError(getApiErrorTranslationKey((e as AxiosError).message));
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const errors = validate(passwordForm, FormSchema);
        setFormState({isValid: !errors, errors: errors || {}});
    }, [passwordForm]);


    useEffect(() => {
        const token = QueryString.getToken(location.search);
        if (!token) {
            navigate(pathRegister);
            return;
        }
        setToken(token as string);
        session.clean();
    }, [navigate, location.search]);

    return (
        <div className="wrapper onboarding">
            <div className="register-page">
                <div className="register-form">
                    <div className="main-logo">
                        <LogoSirdataOnboarding/>
                    </div>
                    <div className="description">{textOnboarding("register_invit.description")}</div>
                    {submitted ?
                        <Button size={ButtonSize.BIG} onClick={() => navigate(pathLogin)}>{textOnboarding("form.button.login")}</Button> :
                        <Box spacing={BoxSpacing.XLARGE}>
                            <Form onSubmit={handleSubmit} validationType={FormValidationType.NONE}>
                                <FormLayoutRows>
                                    {message &&
                                        <Alert severity={AlertSeverity.SUCCESS} text={message}/>
                                    }
                                    <FormLayoutRows spacing={FlexContentSpacing.SMALL}>
                                        <FieldBlock content={{direction: FlexContentDirection.COLUMN}}>
                                            <InputPassword
                                                placeholder={textOnboarding("form.field.password")}
                                                value={passwordForm.password}
                                                onChange={(value) => setPasswordForm((prevState) => ({...prevState, password: value}))}
                                                autoFocus
                                            />
                                            {!!(isShowErrors && formState.errors.password) &&
                                                <FormLayoutMessage message={t(`error.${formState.errors.password}`)} severity={AlertSeverity.DANGER}/>
                                            }
                                        </FieldBlock>
                                        <FieldBlock content={{direction: FlexContentDirection.COLUMN}}>
                                            <InputPassword
                                                placeholder={textOnboarding("form.placeholder.confirm_new_password")}
                                                value={passwordForm.confirmPassword}
                                                onChange={(value) => setPasswordForm((prevState) => ({...prevState, confirmPassword: value}))}
                                            />
                                            {!!(isShowErrors && formState.errors.confirmPassword) &&
                                                <FormLayoutMessage message={t(`error.${formState.errors.confirmPassword}`)} severity={AlertSeverity.DANGER}/>
                                            }
                                        </FieldBlock>
                                    </FormLayoutRows>
                                    <FlexContent direction={FlexContentDirection.COLUMN} spacing={FlexContentSpacing.XSMALL} alignment={FlexContentAlignment.CENTER}>
                                        <FormLayoutButtons>
                                            <Button size={ButtonSize.BIG} disabled={!passwordForm.password || !passwordForm.confirmPassword} loading={isLoading}>{textOnboarding("form.button.register")}</Button>
                                        </FormLayoutButtons>
                                        {apiError &&
                                            <FormLayoutMessage message={t(`error.${apiError}`, t("error.default"))} severity={AlertSeverity.DANGER}/>
                                        }
                                    </FlexContent>
                                    <Paragraph alignment={ParagraphAlignment.CENTER}>
                                        {textOnboarding("already_registered")} <a href={pathLogin}>{textOnboarding("connect_link")}</a>
                                    </Paragraph>
                                </FormLayoutRows>
                            </Form>
                        </Box>
                    }
                </div>
            </div>
        </div>
    );
}

export default InvitationAccept;
