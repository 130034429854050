import {Origin} from "../../../common/api/model/Origin";
import {LicenseName} from "./LicenseName";

type ProductLicense = {
    id: number;
    name: LicenseName;
    isPaymentInfoRequired?: boolean;
}

export class LicensedProduct {
    static CMP: LicensedProduct = new LicensedProduct(Origin.CMP, [
        {id: 3, name: LicenseName.CMP_FREEMIUM},
        {id: 9, name: LicenseName.CMP_PREMIUM_ENTERPRISE, isPaymentInfoRequired: true}]
    );
    static GTM_SERVER_SIDE: LicensedProduct = new LicensedProduct(Origin.GTM_SERVER_SIDE, [
        {id: 13, name: LicenseName.GTM_SERVER_SIDE, isPaymentInfoRequired: true}
    ]);
    static HELPER: LicensedProduct = new LicensedProduct(Origin.HELPER, [
        {id: 10, name: LicenseName.HELPER, isPaymentInfoRequired: true}
    ]);

    origin: Origin;
    licenses: ProductLicense[] = [];

    constructor(origin: Origin, licenses: ProductLicense[]) {
        this.origin = origin;
        this.licenses = licenses;
    }

    static values(): LicensedProduct[] {
        return [
            LicensedProduct.CMP,
            LicensedProduct.GTM_SERVER_SIDE,
            LicensedProduct.HELPER
        ];
    }

    static getLicenses(origin?: Origin): ProductLicense[] {
        if (origin) {
            return this.values().find((it) => it.origin.name === origin.name)?.licenses || [];
        }

        return this.values().reduce((licenses: ProductLicense[], it) => {
            licenses.push(...it.licenses);
            return licenses;
        }, []);
    }

    static getLicenseByName(name: LicenseName): ProductLicense | undefined {
        return this.getLicenses().find((it) => it.name === name);
    }

    static getDefaultLicense(origin: Origin, premium?: boolean): ProductLicense | undefined {
        if (origin.name === Origin.CMP.name) {
            return this.getLicenseByName(premium ? LicenseName.CMP_PREMIUM_ENTERPRISE : LicenseName.CMP_FREEMIUM);
        }

        const licenses = this.getLicenses(origin);
        return !!licenses.length ? licenses[0] : undefined;
    }
}
