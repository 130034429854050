import {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import validate from "validate.js";
import {session} from "../../api/ApiSession";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import {PartnerAccount} from "../../api/model/account/PartnerAccount";
import {AccountType} from "../../common/api/model/account/AccountType";
import {AccountInvitation} from "../../api/model/invitation/AccountInvitation";
import {TranslationPortalFile} from "../../utils/constants";
import {MainContent, Wrapper} from "../../common/component/widget";
import ModalPartnerInvitation from "../../component/modal/ModalPartnerInvitation";
import {AlertSeverity, Button, ButtonSize, ButtonStyle, Color, ContentBlock, ContentBlockRow, ContentBlockRowElementRight, ContentBlockRowTitle, LayoutRows, Loadable, MainContentPageHeader, MainContentStyle, Table, TableColumnStyle} from "@sirdata/ui-lib";
import {InvitationRow, PartnerRow} from "../../component/snippet";
import {Account} from "../../common/api/model/account/Account";
import {MainHeader} from "../../common/component/snippet";
import {UIEventManager} from "../../common/utils/UIEventManager";
import {usePortalContext} from "../../common/context/PortalContext";

const schema = {
    email: {email: {message: () => "^invalid_mail"}}
};

function Accounts() {
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const {t: textAccessAccounts} = useTranslation(TranslationPortalFile.ACCESS_ACCOUNTS);
    const {portalSetting} = usePortalContext();
    const [isLoading, setLoading] = useState(true);
    const [isLoadingPartnerAccounts, setLoadingPartnerAccounts] = useState(true);
    const [loggedAccount, setLoggedAccount] = useState<Account>();

    const [partnerAccounts, setPartnerAccounts] = useState<PartnerAccount[]>([]);
    const [invitations, setInvitations] = useState<AccountInvitation[]>([]);
    const [isShowPartnerInvitationModal, setShowPartnerInvitationModal] = useState(false);

    const togglePartnerAccountType = async (partnerAccount: PartnerAccount) => {
        if (partnerAccount.type === AccountType.OWNER) return;

        try {
            partnerAccount.load({type: (partnerAccount.type === AccountType.USER) ? AccountType.ADMIN : AccountType.USER});
            await session.restPartnerAccount.updatePartnerAccount(partnerAccount);
            UIEventManager.alert(textAccessAccounts("account.update_type_success"), AlertSeverity.SUCCESS);
            await loadPartnerAccounts();
        } catch (e) {
            if (e instanceof ErrorResponse) {
                UIEventManager.alert(t("error." + e.message.replaceAll(" ", "_"), e.message), AlertSeverity.DANGER);
            }
        }
    };

    const togglePartnerAccountStatus = async (partnerAccount: PartnerAccount) => {
        if (partnerAccount.type === AccountType.OWNER) return;

        try {
            partnerAccount.load({active: !partnerAccount.active});
            await session.restPartnerAccount.updatePartnerAccount(partnerAccount);
            UIEventManager.alert(textAccessAccounts("account.update_status_success"), AlertSeverity.SUCCESS);
            await loadPartnerAccounts();
        } catch (e) {
            if (e instanceof ErrorResponse) {
                UIEventManager.alert(t("error." + e.message.replaceAll(" ", "_"), e.message), AlertSeverity.DANGER);
            }
        }
    };

    const sendInvitations = async (emails: string[]) => {
        setShowPartnerInvitationModal(false);
        try {
            for (let i = 0; i < emails.length; i++) {
                const email = emails[i];

                if (email) {
                    const isEmailInvalid = validate({email: email}, schema);
                    if (isEmailInvalid) {
                        UIEventManager.alert(`${email} : ${t("error.email_invalid")}`, AlertSeverity.DANGER);
                        continue;
                    }

                    if (!!invitations.find((item) => item.email === email)) {
                        UIEventManager.alert(`${email} : ${t("error.invitation_already_sent_to", {email: email})}`, AlertSeverity.DANGER);
                        continue;
                    }

                    try {
                        await session.restInvitation.send(email, false);
                        UIEventManager.alert(`${email} : ${textAccessAccounts("message.invitation_sent")}`, AlertSeverity.SUCCESS);
                    } catch (e) {
                        if (e instanceof ErrorResponse) {
                            UIEventManager.alert(`${email} : ${t("error." + e.message.replaceAll(" ", "_"), e.message)}`, AlertSeverity.DANGER);
                        }
                    }
                }
            }
        } catch (e) {
        } finally {
            await loadInvitations();
        }
    };

    const resendInvitation = async (email: string) => {
        setShowPartnerInvitationModal(false);

        const errors = validate({email: email}, schema);
        if (errors) {
            UIEventManager.alert(t("error.email_invalid"), AlertSeverity.DANGER);
            return;
        }

        try {
            await session.restInvitation.send(email, true);
            UIEventManager.alert(textAccessAccounts("message.invitation_resent"), AlertSeverity.SUCCESS);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                UIEventManager.alert(t("error." + e.message.replaceAll(" ", "_"), e.message), AlertSeverity.DANGER);
            }
        }
    };

    const cancelInvitation = async (email: string) => {
        try {
            await session.restInvitation.cancel(email);
            UIEventManager.alert(textAccessAccounts("message.invitation_canceled"), AlertSeverity.SUCCESS);

            await loadInvitations();
        } catch (e) {
            if (e instanceof ErrorResponse) {
                UIEventManager.alert(t("error." + e.message.replaceAll(" ", "_"), e.message), AlertSeverity.DANGER);
            }
        }
    };

    const loadInvitations = useCallback(async () => {
        setInvitations(await session.restInvitation.getPendingInvitations());
    }, []);

    const loadPartnerAccounts = useCallback(async () => {
        try {
            setLoadingPartnerAccounts(true);
            const partnerAccounts = await session.getPartnerAccounts();
            setPartnerAccounts(partnerAccounts);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                UIEventManager.alert(t("error." + e.message.replaceAll(" ", "_"), e.message), AlertSeverity.DANGER);
            }
        } finally {
            setLoadingPartnerAccounts(false);
        }
    }, [t]);

    useEffect(() => {
        (async function () {
            try {
                const loggedAccount = await session.getAccount();
                setLoggedAccount(loggedAccount);

                await loadPartnerAccounts();

                await loadInvitations();
            } catch (e) {
            } finally {
                setLoading(false);
            }
        })();
    }, [loadPartnerAccounts, loadInvitations]);

    return (
        <Wrapper>
            <MainHeader/>
            <MainContent style={MainContentStyle.NARROW_WIDTH}>
                <MainContentPageHeader
                    title={textAccessAccounts("title")}
                    icon={{name: "manage_accounts", colorIcon: Color.OCEAN, outlined: true}}
                />
                <LayoutRows>
                    <Loadable loading={isLoading}>
                        <ContentBlock
                            header={{
                                title: {label: textAccessAccounts("authorized_accounts")}
                            }}
                        >
                            <Loadable loading={isLoadingPartnerAccounts}>
                                <Table
                                    columns={[
                                        {width: 10, label: textAccessAccounts("list.array_header.status"), styles: TableColumnStyle.ALIGN_CENTER},
                                        {width: 40, label: textAccessAccounts("list.array_header.name"), styles: TableColumnStyle.FIXED_WIDTH},
                                        {width: 35, label: textAccessAccounts("list.array_header.email"), styles: TableColumnStyle.HIDE_SCREEN_SMALL},
                                        {width: 25, label: textAccessAccounts("list.array_header.type"), styles: TableColumnStyle.HIDE_SCREEN_MEDIUM},
                                        {width: 5, label: textAccessAccounts("list.array_header.actions")}
                                    ]}
                                    themeWhite={portalSetting.hasThemeWhite()}
                                >
                                    {partnerAccounts.map((account) =>
                                        <PartnerRow key={account.email} account={account} onToggleType={togglePartnerAccountType} onToggleStatus={togglePartnerAccountStatus}/>)}
                                </Table>
                            </Loadable>
                        </ContentBlock>
                        {(loggedAccount?.type && [AccountType.OWNER, AccountType.ADMIN].includes(loggedAccount.type)) &&
                            <ContentBlock>
                                <ContentBlockRow>
                                    <ContentBlockRowTitle label={textAccessAccounts("pending_invitations")}/>
                                    <ContentBlockRowElementRight>
                                        <Button
                                            key={"send-invitation-button"}
                                            size={ButtonSize.MEDIUM}
                                            style={ButtonStyle.PRIMARY_GREEN}
                                            onClick={() => setShowPartnerInvitationModal(true)}
                                            icon={{name: "email"}}
                                        >
                                            {textAccessAccounts("button_send_invit")}
                                        </Button>
                                    </ContentBlockRowElementRight>
                                </ContentBlockRow>
                                <Table
                                    columns={[
                                        {width: 35, label: textAccessAccounts("list.array_header.email"), styles: TableColumnStyle.FIXED_WIDTH},
                                        {width: 30, label: textAccessAccounts("list.array_header.sent_by"), styles: [TableColumnStyle.FIXED_WIDTH, TableColumnStyle.HIDE_SCREEN_SMALL]},
                                        {width: 15, label: textAccessAccounts("list.array_header.sent_on"), styles: TableColumnStyle.HIDE_SCREEN_MEDIUM},
                                        {width: 15, label: textAccessAccounts("list.array_header.expires_on"), styles: TableColumnStyle.HIDE_SCREEN_MEDIUM},
                                        {width: 5, label: textAccessAccounts("list.array_header.actions")}
                                    ]}
                                    themeWhite={portalSetting.hasThemeWhite()}
                                >
                                    {invitations.map((invitation: AccountInvitation) =>
                                        <InvitationRow key={invitation.email} invitation={invitation} onResend={(email) => resendInvitation(email)} onCancel={cancelInvitation}/>)
                                    }
                                </Table>
                            </ContentBlock>
                        }
                        <ModalPartnerInvitation onClose={() => setShowPartnerInvitationModal(false)} active={isShowPartnerInvitationModal} onSendInvitations={sendInvitations}/>
                    </Loadable>
                </LayoutRows>
            </MainContent>
        </Wrapper>
    );
}

export default Accounts;
