import {Rest} from "../../common/api/rest/Rest";
import {AccountRequest} from "../model/account/AccountRequest";
import {UpdatePassword} from "../model/account/UpdatePassword";
import {ConfirmEmail} from "../model/account/ConfirmEmail";
import {PartnerAccount} from "../model/account/PartnerAccount";
import {Country} from "../../common/api/model/Country";
import {EmailInfo} from "../model/account/EmailInfo";
import {Locale} from "../../common/utils/Locale";
import {Origin} from "../../common/api/model/Origin";

export class RestPartnerAccount extends Rest {
    async create(email: string, password: string, origin: Origin, licenseName?: string): Promise<void> {
        const accountRequest = new AccountRequest();
        accountRequest.email = email;
        accountRequest.password = password;
        accountRequest.creation_service = origin.service;
        accountRequest.user = ([Origin.CMP.name, Origin.HELPER.name, Origin.GTM_SERVER_SIDE.name].includes(origin.name)) ? "nga@sirdata.fr" : "";

        const emailInfo = new EmailInfo();
        emailInfo.origin = origin.name;
        if (licenseName) {
            emailInfo.setReplacement("##LICENSE_NAME##", licenseName);
        }
        accountRequest.email_info = emailInfo;

        await this._client.post("/auth/register", accountRequest);
        return;
    }

    async get(): Promise<PartnerAccount> {
        return this._client.get("/auth/account", PartnerAccount) as Promise<PartnerAccount>;
    }

    async update(account: PartnerAccount): Promise<PartnerAccount> {
        return this._client.put("/auth/account", account, PartnerAccount) as Promise<PartnerAccount>;
    }

    async updatePassword(oldPassword: string, newPassword: string): Promise<void> {
        return this._client.post("/auth/change-password", new UpdatePassword().withOldPassword(oldPassword).withNewPassword(newPassword));
    }

    async confirmEmail(token: string): Promise<void> {
        return this._client.post("/auth/email-update-confirm", new ConfirmEmail().withToken(token));
    }

    async getPartnerAccounts(): Promise<PartnerAccount[]> {
        return this._client.get("/auth/partner-account", PartnerAccount) as Promise<PartnerAccount[]>;
    }

    async updatePartnerAccount(account: PartnerAccount): Promise<PartnerAccount> {
        return this._client.put("/auth/partner-account", account, PartnerAccount) as Promise<PartnerAccount>;
    }

    async getCountries(): Promise<Country[]> {
        const langId = Locale.isFrench() ? 2 : 1;
        return this._client.get("/partner/country/" + langId, Country) as Promise<Country[]>;
    }
}
