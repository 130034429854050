export class HeaderModule {
    static CONTACT: HeaderModule = new HeaderModule("contact");
    static NOTIFICATIONS: HeaderModule = new HeaderModule("notifications");
    static SEARCH: HeaderModule = new HeaderModule("search");

    name: string = "";

    constructor(name: string) {
        this.name = name;
    }

    static values(): HeaderModule[] {
        return [
            HeaderModule.CONTACT,
            HeaderModule.NOTIFICATIONS,
            HeaderModule.SEARCH
        ];
    }

    static getByName(name: string): HeaderModule | undefined {
        return this.values().find((it) => it.name === name);
    }
}
