export enum BillingInfoField {
    COMPANY_NAME = "company_name",
    CONTACT_FIRSTNAME = "contact_firstname",
    CONTACT_LASTNAME = "contact_lastname",
    ADDRESS = "address",
    POSTAL_CODE = "postal_code",
    CITY = "city",
    COUNTRY = "country_iso",
    VAT = "vat",
    IBAN = "iban",
    BIC = "bic",
    BUSINESS_ID = "business_id",
    PAYMENT_METHOD = "payment_method",
    PAYMENT_TYPE = "payment_type"
}
