import {Rest} from "../../common/api/rest/Rest";
import {Statistics} from "../model/incomes/Statistics";
import {Site} from "../model/incomes/Site";
import {session} from "../ApiSession";

export class RestPartner extends Rest {
    rootPath = `/partner/${session.partnerId}`;

    getStats(start: string, end: string, siteId?: number): Promise<Statistics> {
        return this._client.get(`${this.rootPath}/daily-stats/start/${start}/end/${end}${siteId ? "/site/" + siteId : ""}`, Statistics) as Promise<Statistics>;
    }

    getStatsMonthly(): Promise<Statistics> {
        return this._client.get(`${this.rootPath}/monthly-stats`, Statistics) as Promise<Statistics>;
    }

    getSites(): Promise<Site[]> {
        return this._client.get(`${this.rootPath}/sites`, Site) as Promise<Site[]>;
    }
}
