import React, {FunctionComponent, useEffect, useState} from "react";
import {LoggedAccount} from "../../api/interface/LoggedAccount";
import {PORTAL} from "../../../utils/Portal";
import {session} from "../../../api/ApiSession";
import {Origin} from "../../api/model/Origin";
import {useTranslation} from "react-i18next";
import {Color, MainAlertBar, Wrapper as WrapperLib} from "@sirdata/ui-lib";
import {Alerting} from "../../api/model/portal/Alerting";
import {TranslationCommonFile} from "../../utils/constants";
import clsx from "clsx";
import {usePortalContext} from "../../context/PortalContext";

export type WrapperProps = {
    children: React.ReactNode;
    cssClass?: string;
};

const Wrapper: FunctionComponent<WrapperProps> = ({children, cssClass}) => {
    const {t: textMain} = useTranslation(TranslationCommonFile.MAIN);
    const [account, setAccount] = useState<LoggedAccount>();
    const [alerting, setAlerting] = useState<Alerting>();
    const {portalSetting} = usePortalContext();

    useEffect(() => {
        (async () => {
            try {
                setAccount(await session.getAccount());
                setAlerting(await session.restPortal.getAlerting());
            } catch (e) {
            }
        })();
    }, []);

    const isLoggedAsPartner = PORTAL.origin !== Origin.CONSOLE && account?.isUser() && !!account?.id;

    return (
        <WrapperLib cssClass={clsx(portalSetting.theme.cssClass, cssClass)}>
            {isLoggedAsPartner &&
                <MainAlertBar
                    text={textMain("logged_as_partner", {id: account.id, name: account.fullName})}
                    colors={{backgroundColor: Color.CORAL, color: Color.WHITE}}
                    icon={{name: "warning", outlined: true}}
                />
            }
            {alerting &&
                <MainAlertBar
                    icon={{name: "feedback", outlined: true}}
                    text={alerting.text}
                    severity={alerting.severity}
                />
            }
            {children}
        </WrapperLib>
    );
};

export default Wrapper;
