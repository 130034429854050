import React, {FormEvent, useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import validate from "validate.js";

import {session} from "../../api/ApiSession";
import {ErrorResponse} from "../../common/api/http/ErrorResponse";
import getApiErrorTranslationKey from "../../api/model/ApiErrors";
import {TranslationPortalFile} from "../../utils/constants";
import {pathLogin} from "../../common/utils/constants";
import {Action, AlertSeverity, Box, BoxSpacing, Button, ButtonLink, ButtonLinkStyle, ButtonSize, FieldBlock, FlexContent, FlexContentAlignment, FlexContentDirection, FlexContentSpacing, Form, FormLayoutButtons, FormLayoutRows, FormValidationType, InputEmail, TranslationLibFile} from "@sirdata/ui-lib";
import {FormSchema, FormState, LogoSirdataOnboarding} from "../../component/snippet";
import {HttpStatusCode} from "../../common/api/http/HttpStatusCode";
import {FormLayoutMessage} from "../../common/component/snippet";

function ForgottenPassword() {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const {t: textOnboarding} = useTranslation(TranslationPortalFile.ONBOARDING);
    const location = useLocation();
    const navigate = useNavigate();

    const [formState, setFormState] = useState<FormState>({isValid: false, errors: {}});
    const [isShowErrors, setShowErrors] = useState(false);
    const [apiError, setApiError] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [email, setEmail] = useState("");

    useEffect(() => session.saveOrigin(location.search), [location.search]);

    useEffect(() => {
        const errors = validate({email: email}, FormSchema);
        setFormState((prevFormState) => ({
            ...prevFormState,
            isValid: !errors,
            errors: errors || {}
        }));
    }, [email]);

    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();

        if (!formState.isValid) {
            setShowErrors(true);
            return;
        }
        setLoading(true);
        try {
            await session.sendResetPasswordMail(email);
            setSubmitted(true);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                if (e.statusCode === HttpStatusCode.INTERNAL_SERVER_ERROR) {
                    setSubmitted(true);
                } else setApiError(getApiErrorTranslationKey(e.message));
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="wrapper onboarding">
            <div className="register-page">
                <div className="register-form">
                    {
                        submitted ?
                            <FlexContent direction={FlexContentDirection.COLUMN} alignment={FlexContentAlignment.CENTER}>
                                <div className="description">{textOnboarding("forgotten_password.instructions_sent")}</div>
                                <div className="description"><strong>{email}</strong></div>
                                <ButtonLink style={ButtonLinkStyle.WHITE} onClick={() => navigate(pathLogin)}>{textOnboarding("form.button.back_to_login")}</ButtonLink>
                            </FlexContent> :
                            <>
                                <div className="main-logo">
                                    <LogoSirdataOnboarding/>
                                </div>
                                <div className="description">{textOnboarding("forgotten_password.description")}</div>
                                <Box spacing={BoxSpacing.XLARGE} cssClass="register-form__forgotten-password">
                                    <Form onSubmit={handleSubmit} validationType={FormValidationType.NONE}>
                                        <FormLayoutRows>
                                            <FieldBlock content={{direction: FlexContentDirection.COLUMN}}>
                                                <InputEmail
                                                    placeholder={textOnboarding("form.field.email")}
                                                    value={email}
                                                    onChange={setEmail}
                                                    autoFocus
                                                />
                                                {!!(isShowErrors && formState.errors.email) &&
                                                    <FormLayoutMessage message={t(`error.${formState.errors.email}`)} severity={AlertSeverity.DANGER}/>
                                                }
                                            </FieldBlock>
                                            <FlexContent direction={FlexContentDirection.COLUMN} spacing={FlexContentSpacing.XSMALL} alignment={FlexContentAlignment.CENTER}>
                                                <FormLayoutButtons>
                                                    <Button size={ButtonSize.BIG} disabled={!email} loading={isLoading}>{textCommon(Action.SEND.labelKey)}</Button>
                                                </FormLayoutButtons>
                                                {apiError &&
                                                    <FormLayoutMessage message={t(`error.${apiError}`, t("error.default"))} severity={AlertSeverity.DANGER}/>
                                                }
                                            </FlexContent>
                                            <FormLayoutButtons>
                                                <ButtonLink style={ButtonLinkStyle.MIDNIGHT_LIGHT} onClick={() => navigate(pathLogin)}>{textOnboarding("form.button.back_to_login")}</ButtonLink>
                                            </FormLayoutButtons>
                                        </FormLayoutRows>
                                    </Form>
                                </Box>
                            </>
                    }
                </div>
            </div>
        </div>
    );
}

export default ForgottenPassword;
