import {AxiosError, AxiosResponse} from "axios";

type ErrorResponseData = {
    message: string;
    code?: string;
}

export class ErrorResponse {
    private error: AxiosError<ErrorResponseData, any>;

    constructor(error: AxiosError<ErrorResponseData, any>) {
        this.error = error;
    }

    get response(): AxiosResponse | undefined {
        return this.error.response;
    }

    get message(): string {
        return this.response?.data?.message || JSON.stringify(this.response?.data);
    }

    get statusCode(): number {
        return this.response?.status || 0;
    }

    /*------------------------------------*/
    /* `code` must be removed in the      */
    /* future and use `message` instead   */
    /*------------------------------------*/

    get code(): string {
        return this.response?.data?.code || this.message || "";
    }
}
