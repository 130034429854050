import axios, {AxiosResponse} from "axios";
import {ErrorResponse} from "../common/api/http/ErrorResponse";
import {session} from "./ApiSession";
import {PORTAL} from "../utils/Portal";
import {QueryString} from "../common/api/model/QueryString";
import {Locale} from "../common/utils/Locale";
import {pathResetPassword} from "../utils/constants";
import {CommonApiClient} from "../common/api/CommonApiClient";

export class ApiClient extends CommonApiClient {
    async sendResetPasswordMail(email: string, origin?: string) {
        try {
            let body = {
                "email": email,
                "email_info": {
                    "origin": PORTAL.origin.name,
                    "locale": Locale.getCurrentLocale(),
                    "token_link": pathResetPassword + (origin ? "?" + QueryString.ORIGIN + "=" + origin : "")
                }
            };
            await this.httpClient.post("/auth/reset-password", body);
        } catch (error) {
            throw (axios.isAxiosError(error) ? new ErrorResponse(error) : error);
        }
    };

    async resetPassword(token: string, password: string) {
        try {
            await this.httpClient.post("/auth/reset-password-confirm", {token: token, password: password});
        } catch (error) {
            throw (axios.isAxiosError(error) ? new ErrorResponse(error) : error);
        }
    };

    async login(email: string, password: string) {
        try {
            let bodyFormData = new URLSearchParams();
            bodyFormData.set("username", email);
            bodyFormData.set("password", password);

            let opts = this.buildRequestConfig({}, "application/x-www-form-urlencoded", false);
            const resp = await this.httpClient.post("/auth/login", bodyFormData, opts) as AxiosResponse<{ partner_id: number }>;
            const token = resp.headers[this.HEADER_X_AUTH_TOKEN];
            if (token === undefined || token === "") {
                throw new Error("no token returned");
            }
            session.setToken(token);
        } catch (error) {
            throw (axios.isAxiosError(error) ? new ErrorResponse(error) : error);
        }
    }
}
