import {Alert, AlertSeverity, FlexContent, FlexContentAlignment, FlexContentDirection, FlexContentSpacing, Loader, Paragraph, Wrapper} from "@sirdata/ui-lib";
import {Navigate, useLocation, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {ActivationStatus} from "../common/utils/ActivationStatus";
import {Origin} from "../common/api/model/Origin";
import {pathHome} from "../common/utils/constants";
import {PORTAL} from "../utils/Portal";
import {redirectToOriginAccess} from "../utils/helper";
import {session} from "../api/ApiSession";
import {TranslationPortalFile} from "../utils/constants";

function ActivateService() {
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const location = useLocation();
    const navigate = useNavigate();

    const [origin, setOrigin] = useState<Origin>();

    useEffect(() => {
        if (location.search) {
            (async () => {
                try {
                    session.saveOrigin(location.search, true);
                    setOrigin(session.origin);

                    if (session.origin === Origin.ACCOUNT) {
                        navigate(PORTAL.defaultPath, {replace: true});
                    }

                    const account = await session.getAccount();
                    // Activate service and license
                    if (!account.hasAccess(session.origin.name)) {
                        await session.activateService(session.origin);
                    }

                    // Redirect to the service
                    redirectToOriginAccess(ActivationStatus.SUCCESS);
                } catch (e) {
                    redirectToOriginAccess(ActivationStatus.ERROR);
                }
            })();
        }
    }, [navigate, location.search]);

    if (!location.search) {
        return <Navigate to={pathHome}/>;
    }

    return (
        <Wrapper cssClass="activate-service-screen">
            <FlexContent direction={FlexContentDirection.COLUMN} spacing={FlexContentSpacing.MEDIUM} alignment={FlexContentAlignment.CENTER}>
                <Loader small/>
                <Paragraph><span dangerouslySetInnerHTML={{__html: t("message.activation", {service: origin?.productName || "..."})}}/></Paragraph>
                <Alert text={t("alert.waiting_activation")} severity={AlertSeverity.WARNING}/>
            </FlexContent>
        </Wrapper>
    );
}

export default ActivateService;
