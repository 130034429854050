import {ApiModel} from "../../../common/api/model/ApiModel";
import {capitalize} from "../../../common/utils/string";

export type EmailDomainOrganization = {
    name: string;
    group_name: string;
}

type EmailDomainOrganizationGroup = {
    name: string;
    organization_names: string[];
}

type Option = {
    value: string;
    label: string;
}

export class EmailDomainInfos extends ApiModel {
    domain: string = "";
    domain_reserved: boolean = false;
    domain_organization_group?: EmailDomainOrganizationGroup;
    domain_organizations: EmailDomainOrganization[] = [];
    organization_groups: EmailDomainOrganizationGroup[] = [];
    organizations: EmailDomainOrganization[] = [];

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    isDomainOrganizationGroupExist(): boolean {
        return !!this.domain_organization_group?.name;
    }

    buildOption(value?: string): Option | undefined {
        if (value) return ({value, label: capitalize(value)});
    }

    buildOrganizationOptions(): Option[] {
        if (this.isDomainOrganizationGroupExist()) {
            return this.domain_organization_group?.organization_names.map((name) => this.buildOption(name) as Option) || [];
        }
        if (this.domain_organizations.length) {
            return this.domain_organizations.map(({name}) => this.buildOption(name) as Option);
        }
        return this.organizations.map(({name}) => this.buildOption(name) as Option);
    }

    buildOrganizationGroupOptions(): Option[] {
        return this.organization_groups.map(({name}) => this.buildOption(name) as Option);
    }
}
