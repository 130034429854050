import {useState} from "react";
import {useTranslation} from "react-i18next";
import "react-phone-number-input/style.css";
import {TranslationPortalFile} from "../utils/constants";
import {Color, LayoutColumns, LayoutRows, MainContentPageHeader, MainContentStyle} from "@sirdata/ui-lib";
import {MainContent, Wrapper} from "../common/component/widget";
import {FormChangeAccountInfo, FormChangePartnerInfo} from "../component/snippet";
import {MainHeader} from "../common/component/snippet";

function Information() {
    const {t: textInformation} = useTranslation(TranslationPortalFile.INFORMATION);
    // eslint-disable-next-line
    const [_, setRefresh] = useState(false);

    const refreshState = () => {
        setRefresh((prev) => !prev);
    };

    return (
        <Wrapper>
            <MainHeader/>
            <MainContent style={MainContentStyle.NARROW_WIDTH}>
                <MainContentPageHeader
                    title={textInformation("title")}
                    icon={{name: "account_circle", colorIcon: Color.CYAN, outlined: true}}
                />
                <LayoutRows>
                    <LayoutColumns>
                        <FormChangePartnerInfo/>
                        <FormChangeAccountInfo onSuccess={refreshState}/>
                    </LayoutColumns>
                </LayoutRows>
            </MainContent>
        </Wrapper>
    );
}

export default Information;
