import React from "react";
import {SvgSirdataAudienceLogoWhite, SvgSirdataLogoMidnight, SvgSirdataLogoWhite} from "@sirdata/ui-lib";

export class Theme {
    static SIRDATA_AUDIENCE_MIDNIGHT: Theme = new Theme("SIRDATA_AUDIENCE_MIDNIGHT", "theme-sirdata-midnight", SvgSirdataAudienceLogoWhite);
    static SIRDATA_MIDNIGHT: Theme = new Theme("SIRDATA_MIDNIGHT", "theme-sirdata-midnight", SvgSirdataLogoWhite);
    static SIRDATA_WHITE: Theme = new Theme("SIRDATA_WHITE", "theme-sirdata-white", SvgSirdataLogoMidnight);

    name: string = "";
    cssClass: string = "";
    logo: React.FunctionComponent = SvgSirdataLogoWhite;

    constructor(name: string, cssClass: string, logo: React.FunctionComponent) {
        this.name = name;
        this.cssClass = cssClass;
        this.logo = logo;
    }

    static values(): Theme[] {
        return [
            Theme.SIRDATA_AUDIENCE_MIDNIGHT,
            Theme.SIRDATA_MIDNIGHT,
            Theme.SIRDATA_WHITE
        ];
    }

    static getByName(name: string | undefined): Theme {
        return this.values().find((it) => it.name === name) || Theme.SIRDATA_MIDNIGHT;
    }
}
