import {ApiModel} from "../../../common/api/model/ApiModel";

export class Stat extends ApiModel {
    date: string = "";
    count: number = 0;
}

export class Statistics extends ApiModel {
    views: Stat[] = [];
    hashmails: Stat[] = [];

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

}
