import {Locale} from "./Locale";

/* ROUTES */
export const routeParamId = "/:id";
export const routeParamPath = "/:path";

export const pathError403 = "/403";
export const pathError404 = "/404";
export const pathError500 = "/500";

export const pathAccess = "/access";
export const pathContact = "/contact";
export const pathHome = "/home";
export const pathLegalNotice = "/legal-notice";
export const pathLogin = "/login";
export const pathSso = "/sso";

/* TRANSLATIONS */
export enum TranslationCommonFile {
    CONTACT = "contact",
    ERRORS = "errors",
    MAIN = "main"
}

export const TRANSLATION_COMMON_FILES = [
    TranslationCommonFile.CONTACT,
    TranslationCommonFile.ERRORS,
    TranslationCommonFile.MAIN
];

export const sirdataDataPrivacyPolicyUrl = Locale.isFrench() ? "https://www.sirdata.com/fr/Vie-Privee-Marketing" : "https://www.sirdata.com/en/Privacy-Marketing";
