import React, {FunctionComponent} from "react";
import {CodeBlock, FormLayoutRows, ModalContent, ModalDescription, ModalNew, ModalTitle} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../utils/constants";

export type ModalApiTokenProps = {
    token: string;
    active: boolean;
    onClose: () => void;
};

const ModalShowApiToken: FunctionComponent<ModalApiTokenProps> = ({token, onClose, active}) => {
    const {t: textAccessApiTokens} = useTranslation(TranslationPortalFile.ACCESS_API_TOKENS);

    return (
        <ModalNew onClose={onClose} active={active}>
            <ModalContent>
                <ModalTitle>{textAccessApiTokens("modal.token.title")}</ModalTitle>
                <FormLayoutRows>
                    <ModalDescription>
                        <span dangerouslySetInnerHTML={{__html: textAccessApiTokens("modal.token.text")}}/>
                    </ModalDescription>
                    <CodeBlock header={textAccessApiTokens("table.action.copy_token")} code={token} copiable/>
                </FormLayoutRows>
            </ModalContent>
        </ModalNew>
    );
};

export default ModalShowApiToken;
