import React, {memo, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {ModalNotification} from "@sirdata/ui-lib";
import {session} from "../../../api/ApiSession";
import {TranslationCommonFile} from "../../utils/constants";
import {UserNotificationContent} from "../../api/model/notification/UserNotificationContent";

const MainModalNotification = () => {
    const {t: textMain} = useTranslation(TranslationCommonFile.MAIN);
    const navigate = useNavigate();
    const [isActive, setActive] = useState(false);
    const [notificationContent, setNotificationContent] = useState<UserNotificationContent>(new UserNotificationContent());
    const [notificationId, setNotificationId] = useState<number>(0);

    useEffect(() => {
        (async () => {
            try {
                const [modalNotification] = await session.restUserNotifications.getModalNotifications();

                if (modalNotification) {
                    setNotificationContent(modalNotification.content);
                    setNotificationId(modalNotification.id);
                    setActive(true);
                }
            } catch (e) {}
        })();
    }, []);

    const handleClose = async () => {
        try {
            await session.restUserNotifications.markAsRead(notificationId);
        } catch (e) {
        } finally {
            setActive(false);
        }
    };

    const handleCallToAction = async () => {
        await handleClose();

        if (notificationContent?.url) {
            if (notificationContent.isUrlExternal()) {
                window.open(notificationContent.url, "_blank");
            } else {
                navigate(notificationContent.getUrlPathname());
            }
        }
    };

    if (!notificationContent.image) return null;

    return (
        <ModalNotification
            active={isActive}
            content={{
                image: notificationContent.image || "",
                call_to_action: notificationContent.call_to_action || "",
                title: notificationContent.title,
                subtitle: notificationContent.subtitle,
                text: notificationContent.text
            }}
            onClose={handleClose}
            onCallToAction={handleCallToAction}
            subLink={{
                onClick: handleClose,
                label: textMain("modal.notification.stop_notification")
            }}
        />
    );
};

export default memo(MainModalNotification);
