import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../api/ApiSession";
import {Color, LayoutRows, MainContentPageHeader, MainContentStyle} from "@sirdata/ui-lib";
import {IncomeSection, IncomeVolumesSection} from "../../component/snippet";
import {MainContent, RestrictedContent, Wrapper} from "../../common/component/widget";
import {TranslationPortalFile} from "../../utils/constants";
import {Authorization} from "../../api/model/account/Authorization";
import {MainHeader} from "../../common/component/snippet";

function TrafficGlobal() {
    const {t: textTrafficGlobal} = useTranslation(TranslationPortalFile.TRAFFIC_GLOBAL);

    const [partnerId, setPartnerId] = useState(0);

    useEffect(() => {
        (async function () {
            try {
                const account = await session.getPartnerAccount();
                if (!account.partner.id) return;
                setPartnerId(account.partner.id);
            } catch (e) {
            }
        })();
    }, []);

    if (!partnerId) return <></>;

    return (
        <Wrapper>
            <MainHeader/>
            <MainContent style={MainContentStyle.NARROW_WIDTH}>
                <MainContentPageHeader
                    title={textTrafficGlobal("title")}
                    icon={{name: "route", colorIcon: Color.OCEAN, outlined: true}}
                    description={textTrafficGlobal("description")}
                />
                <LayoutRows>
                    <IncomeVolumesSection partnerId={partnerId}/>
                    <RestrictedContent allowedTo={Authorization.TRAFFIC_REVENUE}>
                        <IncomeSection partnerId={partnerId}/>
                    </RestrictedContent>
                </LayoutRows>
            </MainContent>
        </Wrapper>
    );
}

export default TrafficGlobal;
