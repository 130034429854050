import {ApiModel} from "../../../common/api/model/ApiModel";

export class ConfirmEmail extends ApiModel {
    token: string = "";

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getJsonParameters(): {} {
        return {
            token: this.token
        };
    }

    withToken(token: string): ConfirmEmail {
        this.token = token;
        return this;
    }
}
