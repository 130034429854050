import React, {FunctionComponent, useEffect, useState} from "react";
import validate from "validate.js";
import {useTranslation} from "react-i18next";
import {session} from "../../../api/ApiSession";
import getApiErrorTranslationKey from "../../../api/model/ApiErrors";
import {TranslationPortalFile} from "../../../utils/constants";
import {ButtonUpdate, FormSchema, FormState} from "../../../component/snippet";
import {AxiosError} from "axios";
import {Alert, AlertSeverity, ButtonLinkCancel, ButtonSave, FieldBlock, FlexContent, FlexContentAlignment, FlexContentDirection, FlexContentJustify, FormLayoutButtons, FormLayoutMention, FormLayoutRows, InputPassword} from "@sirdata/ui-lib";
import {FormLayoutMessage} from "../../../common/component/snippet";

const FormChangePassword: FunctionComponent<{ onSuccess: Function }> = ({onSuccess}) => {
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const {t: textCredentials} = useTranslation(TranslationPortalFile.CREDENTIALS);

    const [formState, setFormState] = useState<FormState>({isValid: false, errors: {}});
    const [isShowErrors, setShowErrors] = useState(false);
    const [isOpenForm, setOpenForm] = useState(false);
    const [passwordForm, setPasswordForm] = useState<{ oldPassword: string; newPassword: string; confirmPassword: string }>({
        oldPassword: "", newPassword: "", confirmPassword: ""
    });
    const [message, setMessage] = useState("");

    const hasEmptyField = () => !passwordForm.oldPassword || !passwordForm.newPassword || !passwordForm.confirmPassword;

    const handleSubmit = async () => {
        if (!formState.isValid) {
            setShowErrors(true);
            return;
        }
        setShowErrors(false);

        try {
            await session.restPartnerAccount.updatePassword(passwordForm.oldPassword, passwordForm.newPassword);

            setOpenForm(false);
            setPasswordForm({oldPassword: "", newPassword: "", confirmPassword: ""});
            onSuccess(textCredentials("password_form.message.change_password_success"));
        } catch (e) {
            setMessage(t("error." + getApiErrorTranslationKey((e as AxiosError).message), (e as AxiosError).message));
        }
    };

    const closeForm = () => {
        setPasswordForm({oldPassword: "", newPassword: "", confirmPassword: ""});
        setShowErrors(false);
        setOpenForm(false);
    };

    useEffect(() => {
        setMessage("");
        const errors = validate({password: passwordForm.newPassword, confirmPassword: passwordForm.confirmPassword}, FormSchema);
        setFormState({isValid: !errors, errors: errors || {}});
    }, [passwordForm]);

    return (
        <>
            <FlexContent direction={FlexContentDirection.ROW} alignment={FlexContentAlignment.START}>
                <FieldBlock label={textCredentials("password_form.password")}>
                    ********
                </FieldBlock>
                <ButtonUpdate onClick={() => setOpenForm(true)} disabled={isOpenForm}/>
            </FlexContent>
            {isOpenForm &&
                <FormLayoutRows>
                    <FieldBlock label={textCredentials("password_form.field.current_password")} required>
                        <InputPassword
                            placeholder={textCredentials("password_form.placeholder.current_password")}
                            value={passwordForm.oldPassword}
                            onChange={(value) => setPasswordForm((prevState) => ({...prevState, oldPassword: value}))}
                            autoFocus
                        />
                    </FieldBlock>
                    <FieldBlock
                        label={textCredentials("password_form.field.new_password")}
                        content={{direction: FlexContentDirection.COLUMN}}
                        required
                    >
                        <InputPassword
                            placeholder={textCredentials("password_form.placeholder.new_password")}
                            value={passwordForm.newPassword}
                            onChange={(value) => setPasswordForm((prevState) => ({...prevState, newPassword: value}))}
                        />
                        {!!(isShowErrors && formState.errors.password) &&
                            <FormLayoutMessage message={t(`error.${formState.errors.password}`)} severity={AlertSeverity.DANGER}/>
                        }
                    </FieldBlock>
                    <FieldBlock
                        label={textCredentials("password_form.field.confirm_password")}
                        content={{direction: FlexContentDirection.COLUMN}}
                        required
                    >
                        <InputPassword
                            placeholder={textCredentials("password_form.placeholder.confirm_new_password")}
                            value={passwordForm.confirmPassword}
                            onChange={(value) => setPasswordForm((prevState) => ({...prevState, confirmPassword: value}))}
                        />
                        {!!(isShowErrors && formState.errors.confirmPassword) &&
                            <FormLayoutMessage message={t(`error.${formState.errors.confirmPassword}`)} severity={AlertSeverity.DANGER}/>
                        }
                    </FieldBlock>
                    <FormLayoutMention/>
                    <FormLayoutButtons justify={FlexContentJustify.END}>
                        <ButtonLinkCancel onClick={closeForm}/>
                        <ButtonSave onClick={handleSubmit} disabled={hasEmptyField()}/>
                    </FormLayoutButtons>
                    {message &&
                        <Alert text={message} severity={AlertSeverity.DANGER}/>
                    }
                </FormLayoutRows>
            }
        </>
    );
};

export default FormChangePassword;

