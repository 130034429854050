import {EmailInfo} from "../account/EmailInfo";
import {ApiModel} from "../ApiModel";

export class EmailNotificationRequest extends ApiModel {
    email_info: EmailInfo = new EmailInfo();
    email_type: string = "";

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    toJson(): string {
        return JSON.stringify(this.getJsonParameters());
    }

    getJsonParameters(): {} {
        return {
            email_info: this.email_info,
            email_type: this.email_type
        };
    }
}
