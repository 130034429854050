import {ApiModel} from "../../common/api/model/ApiModel";

export class ApiToken extends ApiModel {
    id: number = 0;
    token?: string;
    partner_id: number = 0;
    partner_account_id?: number;
    name?: string;
    description?: string;
    created_at?: string;
    updated_at?: string;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getJsonParameters(): {} {
        return {
            id: this.id,
            partner_id: this.partner_id,
            partner_account_id: this.partner_account_id,
            name: this.name,
            description: this.description
        };
    }
}
