import React from "react";
import {ChartOptions} from "chart.js";
import {Line} from "react-chartjs-2";
import {GraphData, optionsLinesDaily} from "../../../utils/chart_options";

interface ChartLineProps {
    data: GraphData | undefined;
    options?: ChartOptions<"line">;
}

const ChartLine: React.FC<ChartLineProps> = ({data, options = optionsLinesDaily}) => {
    if (!data) return <></>;

    return <Line data={data} options={options}/>;
};

export default ChartLine;
