import React, {FunctionComponent, useState} from "react";
import {useTranslation} from "react-i18next";
import {Action, AlertSeverity, Button, ButtonSize, FieldBlock, FormLayoutButtons, FormLayoutRows, InputEmail, ModalContent, ModalNew, ModalTitle, Textarea, TranslationLibFile} from "@sirdata/ui-lib";
import {LoggedAccount} from "../../common/api/interface/LoggedAccount";
import {ContactRequest} from "../../common/api/model/account/ContactRequest";
import {session} from "../../api/ApiSession";
import {EmailInfo} from "../../common/api/model/account/EmailInfo";
import {Origin} from "../../common/api/model/Origin";
import {TranslationPortalFile} from "../../utils/constants";
import {UIEventManager} from "../../common/utils/UIEventManager";
import {TranslationCommonFile} from "../../common/utils/constants";

type ModalBillingInfoRequestProps = {
    active: boolean;
    account?: LoggedAccount;
    onClose: () => void;
};

const ModalBillingInfoRequest: FunctionComponent<ModalBillingInfoRequestProps> = ({account, active, onClose}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textContact} = useTranslation(TranslationCommonFile.CONTACT);
    const {t: textBillingInfo} = useTranslation(TranslationPortalFile.BILLING_INFO);
    const [message, setMessage] = useState<string>("");
    const [isSubmitting, setSubmitting] = useState<boolean>(false);

    const handleClose = () => {
        onClose();
        setMessage("");
    };

    const handleSubmit = async () => {
        try {
            setSubmitting(true);

            [Origin.CMP, Origin.CUSTOMER, Origin.GTM_SERVER_SIDE, Origin.HELPER].forEach(async (origin) => {
                if (account?.hasAccess(origin.name)) {
                    await session._client.post("/frontend-api/contact", new ContactRequest({
                        email_info: new EmailInfo({origin: origin.name}),
                        topic: textBillingInfo("modal.change_request.form.topic"),
                        message
                    }));
                }
            });

            UIEventManager.alert(textContact("mail_sent"), AlertSeverity.SUCCESS);
        } catch (e) {
            UIEventManager.alert(textContact("mail_error"), AlertSeverity.DANGER);
        } finally {
            setSubmitting(false);
            handleClose();
        }
    };

    return (
        <ModalNew onClose={handleClose} active={active}>
            <ModalContent>
                <ModalTitle>{textBillingInfo("modal.change_request.title")}</ModalTitle>
                <FormLayoutRows>
                    <FieldBlock label={textBillingInfo("modal.change_request.form.from")}>
                        <InputEmail
                            value={account?.email}
                            onChange={() => {}}
                            disabled
                        />
                    </FieldBlock>
                    <FieldBlock label={textBillingInfo("modal.change_request.form.message")} required>
                        <Textarea
                            placeholder={textBillingInfo("modal.change_request.form.message_placeholder")}
                            value={message}
                            onChange={setMessage}
                            rows={10}
                            autoFocus
                        />
                    </FieldBlock>
                    <FormLayoutButtons>
                        <Button size={ButtonSize.BIG} disabled={!message} onClick={handleSubmit} loading={isSubmitting}>
                            {textCommon(Action.SEND.labelKey)}
                        </Button>
                    </FormLayoutButtons>
                </FormLayoutRows>
            </ModalContent>
        </ModalNew>
    );
};

export default ModalBillingInfoRequest;
