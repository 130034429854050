import {ApiModel} from "../../../common/api/model/ApiModel";
import {PartnerOrganizationStatus} from "./PartnerOrganizationStatus";

export class PartnerOrganization extends ApiModel {
    name: string = "";
    group_name?: string;
    status: PartnerOrganizationStatus = PartnerOrganizationStatus.REQUESTED;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getJsonParameters(): {} {
        return {
            name: this.name,
            group_name: this.group_name
        };
    }

    getValidatedGroupName(): string | undefined {
        if (this.status === PartnerOrganizationStatus.VALIDATED) {
            return this.group_name;
        }
    }
}
