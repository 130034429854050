import {Serializable} from "../../../common/api/interface/Serializable";

export class AcceptInvitationRequest implements Serializable {
    token: string = "";
    password: string = "";

    toJson(): string {
        return JSON.stringify(this.getJsonParameters());
    }

    getJsonParameters(): {} {
        return {
            token: this.token,
            password: this.password
        };
    }
}
