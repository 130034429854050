import {ApiModel} from "../../../common/api/model/ApiModel";

export class UpdatePassword extends ApiModel {
    old_password: string = "";
    new_password: string = "";

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getJsonParameters(): {} {
        return {
            old_password: this.old_password,
            new_password: this.new_password
        };
    }

    withOldPassword(password: string): UpdatePassword {
        this.old_password = password;
        return this;
    }

    withNewPassword(password: string): UpdatePassword {
        this.new_password = password;
        return this;
    }

}
