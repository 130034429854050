import React, {FormEvent, useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {AxiosError} from "axios";

import {session} from "../../api/ApiSession";
import getApiErrorTranslationKey from "../../api/model/ApiErrors";
import {pathForgottenPassword, pathRegister, TranslationPortalFile} from "../../utils/constants";
import {pathHome} from "../../common/utils/constants";
import {Origin} from "../../common/api/model/Origin";
import {
    AlertSeverity,
    Box,
    BoxSpacing,
    Button,
    ButtonSize,
    Checkbox,
    ContentBlock,
    FlexContent,
    FlexContentAlignment,
    FlexContentDirection,
    FlexContentJustify,
    FlexContentSpacing,
    Form,
    FormLayoutButtons,
    FormLayoutRows,
    FormValidationType,
    InputCheckbox,
    InputEmail,
    InputPassword,
    Paragraph,
    ParagraphAlignment
} from "@sirdata/ui-lib";
import {getTermsOfUseUrlForOrigin, redirectToOrigin} from "../../utils/helper";
import {LogoSirdataOnboarding} from "../../component/snippet";
import {FormLayoutMessage} from "../../common/component/snippet";

function Login() {
    const location = useLocation();
    const navigate = useNavigate();
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const {t: textOnboarding} = useTranslation(TranslationPortalFile.ONBOARDING);

    const [credentials, setCredentials] = useState({email: "", password: ""});
    const [cguAccount, setCguAccount] = useState(false);
    const [remember, setRemember] = useState(false);
    const [apiError, setApiError] = useState("");
    const [isConnecting, setConnecting] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [origin, setOrigin] = useState<String>();

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        setLoading(true);
        try {
            await session.login(credentials.email, credentials.password, remember);

            if (!redirectToOrigin(remember)) {
                navigate(pathHome);
            } else setConnecting(true);
        } catch (e) {
            setApiError(getApiErrorTranslationKey((e as AxiosError).message));
            setLoading(false);
        }
    };

    useEffect(() => session.saveOrigin(location.search), [location.search]);

    useEffect(() => setOrigin(session.origin.name), []);

    useEffect(() => {
        if (session) {
            session.isLogged()
                .then((logged) => {
                    if (!logged) return;

                    if (!redirectToOrigin(remember)) {
                        navigate(pathHome);
                    } else setConnecting(true);
                })
                .catch(() => {
                });
        }
    }, [navigate, remember]);

    return (
        <div className="wrapper onboarding">
            <div className="register-page">
                <div className="register-form">
                    <div className="main-logo">
                        <LogoSirdataOnboarding/>
                    </div>
                    {isConnecting ?
                        <ContentBlock>
                            <Box spacing={BoxSpacing.XLARGE}>
                                {textOnboarding("login.connecting")}
                            </Box>
                        </ContentBlock> :
                        <>
                            <div className="description">{textOnboarding("login.description." + origin)}</div>
                            <ContentBlock>
                                <Box spacing={BoxSpacing.XLARGE}>
                                    <Form onSubmit={handleSubmit} validationType={FormValidationType.NONE}>
                                        <FormLayoutRows>
                                            <FormLayoutRows spacing={FlexContentSpacing.XSMALL}>
                                                <FormLayoutRows spacing={FlexContentSpacing.SMALL}>
                                                    <InputEmail
                                                        placeholder={textOnboarding("form.field.email")}
                                                        value={credentials.email}
                                                        onChange={(value) => setCredentials((prevState) => ({...prevState, email: value}))}
                                                        autoFocus
                                                    />
                                                    <InputPassword
                                                        placeholder={textOnboarding("form.field.password")}
                                                        value={credentials.password}
                                                        onChange={(value) => setCredentials((prevState) => ({...prevState, password: value}))}
                                                    />
                                                </FormLayoutRows>
                                                <FlexContent direction={FlexContentDirection.COLUMN}>
                                                    <FlexContent direction={FlexContentDirection.ROW} justify={FlexContentJustify.SPACE_BETWEEN}>
                                                        <Checkbox
                                                            name={"remember"}
                                                            label={textOnboarding("login.remember_me")}
                                                            checked={remember}
                                                            onChange={setRemember}
                                                        />
                                                        <a className="right-link" href={pathForgottenPassword}>{textOnboarding("login.forgotten_password")}</a>
                                                    </FlexContent>
                                                    <div className="checkbox-field">
                                                        <InputCheckbox
                                                            id={"accept_account_cgu"}
                                                            checked={cguAccount}
                                                            onChange={setCguAccount}
                                                        />
                                                        <label htmlFor="accept_account_cgu">
                                                            <span dangerouslySetInnerHTML={{__html: textOnboarding("register.cgu.account", {termsOfUseUrl: getTermsOfUseUrlForOrigin(Origin.ACCOUNT)})}}/>
                                                        </label>
                                                    </div>
                                                </FlexContent>
                                            </FormLayoutRows>
                                            <FlexContent direction={FlexContentDirection.COLUMN} alignment={FlexContentAlignment.CENTER} spacing={FlexContentSpacing.XSMALL}>
                                                <FormLayoutButtons>
                                                    <Button size={ButtonSize.BIG} disabled={!credentials.email || !credentials.password || !cguAccount} loading={isLoading}>{textOnboarding("form.button.login")}</Button>
                                                </FormLayoutButtons>
                                                {apiError &&
                                                    <FormLayoutMessage message={t(`error.${apiError}`, t("error.default"))} severity={AlertSeverity.DANGER}/>
                                                }
                                            </FlexContent>
                                            <Paragraph alignment={ParagraphAlignment.CENTER}>
                                                {textOnboarding("login.not_yet_registered")} <a href={pathRegister + location?.search}>{textOnboarding("login.register_link")}</a>
                                            </Paragraph>
                                        </FormLayoutRows>
                                    </Form>
                                </Box>
                            </ContentBlock>
                        </>
                    }
                </div>
            </div>
        </div>
    );
}

export default Login;
