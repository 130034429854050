import {Rest} from "../../common/api/rest/Rest";
import {ApiToken} from "../model/ApiToken";

export class RestApiToken extends Rest {
    rootPath = "/auth/partner-api-token";

    list(): Promise<ApiToken[]> {
        return this._client.get(`${this.rootPath}`, ApiToken) as Promise<ApiToken[]>;
    }

    get(id: number): Promise<ApiToken> {
        return this._client.get(`${this.rootPath}/${id}`, ApiToken) as Promise<ApiToken>;
    }

    create(apiToken: ApiToken): Promise<ApiToken> {
        return this._client.post(`${this.rootPath}`, apiToken, ApiToken) as Promise<ApiToken>;
    }

    update(apiToken: ApiToken): Promise<ApiToken> {
        return this._client.put(`${this.rootPath}/${apiToken.id}`, apiToken, ApiToken) as Promise<ApiToken>;
    }

    delete(id: number) {
        return this._client.delete(`${this.rootPath}/${id}`);
    }
}
