import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {
    Action,
    Alert,
    AlertSeverity,
    Box,
    BoxSpacing,
    Button,
    ButtonSave,
    ButtonSize,
    ButtonStyle,
    ContentBlock,
    FieldBlock,
    FormLayoutButtons,
    FormLayoutColumns,
    FormLayoutMention,
    FormLayoutRows,
    InputText,
    Loadable,
    ModalConfirmMessage,
    ModalContent,
    ModalDescription,
    ModalDescriptionAlignment,
    ModalNew,
    Select,
    SelectAutocomplete,
    TranslationLibFile
} from "@sirdata/ui-lib";
import getApiErrorTranslationKey from "../../../api/model/ApiErrors";
import {BILLING_PAYMENT_TYPES} from "../../../api/model/billing/BillingPaymentType";
import ModalBillingInfoRequest from "../../modal/ModalBillingInfoRequest";
import {session} from "../../../api/ApiSession";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {BillingInfo} from "../../../api/model/billing/BillingInfo";
import {Country, CountryCode} from "../../../common/api/model/Country";
import {TranslationPortalFile} from "../../../utils/constants";
import {BillingInfoField} from "../../../api/model/billing/BillingInfoField";
import {Account} from "../../../common/api/model/account/Account";
import {LicenseName} from "../../../api/model/account/LicenseName";
import {HttpStatusCode} from "../../../common/api/http/HttpStatusCode";
import {UIEventManager} from "../../../common/utils/UIEventManager";
import {EmailNotificationType} from "../../../common/api/model/email/EmailNotificationType";
import {Origin} from "../../../common/api/model/Origin";
import {LicensedProduct} from "../../../api/model/account/LicensedProduct";

const FormChangeBillingInfo = () => {
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textBillingInfo} = useTranslation(TranslationPortalFile.BILLING_INFO);

    const [loggedAccount, setLoggedAccount] = useState<Account>();
    const [billingInfo, setBillingInfo] = useState<BillingInfo>(new BillingInfo());
    const [countries, setCountries] = useState<Country[]>([]);

    const [isLoading, setLoading] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);
    const [isShowModalConfirm, setShowModalConfirm] = useState(false);
    const [isShowModalSuccess, setShowModalSuccess] = useState(false);
    const [isShowPaymentInfo, setShowPaymentInfo] = useState(false);
    const [isShowModalBillingInfoRequest, setShowModalBillingInfoRequest] = useState(false);

    useEffect(() => {
        (async function () {
            try {
                setLoading(true);
                setLoggedAccount(await session.getAccount());

                const account = await session.getPartnerAccount();
                setBillingInfo(new BillingInfo({company_name: account.partner.company}));

                const partnerLicenses = await session.restLicense.list();
                const activePartnerLicenseNames = partnerLicenses.filter((it) => it.active).map((it) => it.license.name as LicenseName);
                const isShowPaymentInfo = activePartnerLicenseNames.some((name) => LicensedProduct.getLicenseByName(name)?.isPaymentInfoRequired);
                setShowPaymentInfo(isShowPaymentInfo);

                setCountries(await session.getCountries());

                const partnerBillingInfo = await session.restBilling.getPartnerBillingInfo();
                if (!partnerBillingInfo.company_name) partnerBillingInfo.company_name = account.partner.company;
                setBillingInfo(partnerBillingInfo);
            } catch (e) {
                if (e instanceof ErrorResponse && e.statusCode !== HttpStatusCode.NOT_FOUND) {
                    UIEventManager.alert(t(`error.${getApiErrorTranslationKey(e.message)}`, t("error.default")), AlertSeverity.DANGER);
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [t]);

    const handleSubmit = async () => {
        try {
            setSubmitting(true);
            await session.restBilling.updatePartnerBillingInfo(billingInfo);

            await session.loadAccount();
            setLoggedAccount(await session.getAccount());

            [Origin.CMP, Origin.CUSTOMER, Origin.GTM_SERVER_SIDE, Origin.HELPER].forEach(async (origin) => {
                if (loggedAccount?.hasAccess(origin.name)) {
                    await session.restPortal.sendEmailNotification(EmailNotificationType.BILLING_INFO_UPDATE, origin);
                }
            });

            setShowModalSuccess(true);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                UIEventManager.alert(t(`error.${getApiErrorTranslationKey(e.message)}`, t("error.default")), AlertSeverity.DANGER);
            }
        } finally {
            setSubmitting(false);
            setShowModalConfirm(false);
        }
    };

    const hasEmptyField = () => {
        return !billingInfo.company_name || !billingInfo.contact_firstname || !billingInfo.contact_lastname
            || !billingInfo.address || !billingInfo.postal_code || !billingInfo.city || !billingInfo.business_id || !billingInfo.vat;
    };

    function isBillingInfoComplete() {
        if (!loggedAccount?.billing_status.freemium && !loggedAccount?.billing_status.premium) {
            return false;
        } else if (isShowPaymentInfo && !loggedAccount?.billing_status.premium) {
            return false;
        }
        return true;
    }

    const currentCountry = countries.find((c) => c.isocode === billingInfo.country_iso);

    return (
        <>
            <Loadable loading={isLoading}>
                {!isBillingInfoComplete() ?
                    <ContentBlock>
                        {(loggedAccount && loggedAccount.services.length === 2 && loggedAccount.services.includes(Origin.AUDIENCE.service)) &&
                            <Alert
                                text={textBillingInfo("message.audience_user_only")}
                                severity={AlertSeverity.WARNING}
                            />
                        }
                        <Box spacing={BoxSpacing.LARGE}>
                            <FormLayoutRows>
                                <FieldBlock label={textBillingInfo(`field.${BillingInfoField.COMPANY_NAME}`)} required>
                                    <InputText
                                        placeholder={textBillingInfo(`placeholder.${BillingInfoField.COMPANY_NAME}`)}
                                        value={billingInfo.company_name}
                                        onChange={(value) => setBillingInfo((prevState) => new BillingInfo({...prevState, [BillingInfoField.COMPANY_NAME]: value}))}
                                        autoFocus
                                    />
                                </FieldBlock>
                                <FormLayoutColumns>
                                    <FieldBlock label={textBillingInfo(`field.${BillingInfoField.CONTACT_FIRSTNAME}`)} required>
                                        <InputText
                                            placeholder={textBillingInfo(`placeholder.${BillingInfoField.CONTACT_FIRSTNAME}`)}
                                            value={billingInfo.contact_firstname}
                                            onChange={(value) => setBillingInfo((prevState) => new BillingInfo({...prevState, [BillingInfoField.CONTACT_FIRSTNAME]: value}))}
                                        />
                                    </FieldBlock>
                                    <FieldBlock label={textBillingInfo(`field.${BillingInfoField.CONTACT_LASTNAME}`)} required>
                                        <InputText
                                            placeholder={textBillingInfo(`placeholder.${BillingInfoField.CONTACT_LASTNAME}`)}
                                            value={billingInfo.contact_lastname}
                                            onChange={(value) => setBillingInfo((prevState) => new BillingInfo({...prevState, [BillingInfoField.CONTACT_LASTNAME]: value}))}
                                        />
                                    </FieldBlock>
                                </FormLayoutColumns>
                                <FormLayoutColumns>
                                    <FieldBlock label={textBillingInfo(`field.${BillingInfoField.ADDRESS}`)} required>
                                        <InputText
                                            placeholder={textBillingInfo(`placeholder.${BillingInfoField.ADDRESS}`)}
                                            value={billingInfo.address}
                                            onChange={(value) => setBillingInfo((prevState) => new BillingInfo({...prevState, [BillingInfoField.ADDRESS]: value}))}
                                        />
                                    </FieldBlock>
                                    <FieldBlock label={textBillingInfo(`field.${BillingInfoField.POSTAL_CODE}`)} required>
                                        <InputText
                                            placeholder={textBillingInfo(`placeholder.${BillingInfoField.POSTAL_CODE}`)}
                                            value={billingInfo.postal_code}
                                            onChange={(value) => setBillingInfo((prevState) => new BillingInfo({...prevState, [BillingInfoField.POSTAL_CODE]: value}))}
                                        />
                                    </FieldBlock>
                                </FormLayoutColumns>
                                <FormLayoutColumns>
                                    <FieldBlock label={textBillingInfo(`field.${BillingInfoField.CITY}`)} required>
                                        <InputText
                                            placeholder={textBillingInfo(`placeholder.${BillingInfoField.CITY}`)}
                                            value={billingInfo.city}
                                            onChange={(value) => setBillingInfo((prevState) => new BillingInfo({...prevState, [BillingInfoField.CITY]: value}))}
                                        />
                                    </FieldBlock>
                                    <FieldBlock label={textBillingInfo(`field.${BillingInfoField.COUNTRY}`)} required>
                                        <SelectAutocomplete
                                            options={countries.map((country) => ({value: country.isocode, label: country.name}))}
                                            value={currentCountry?.isocode || CountryCode.FRANCE.code}
                                            onChange={(option) => setBillingInfo((prevState) => new BillingInfo({...prevState, [BillingInfoField.COUNTRY]: option?.value}))}
                                        />
                                    </FieldBlock>
                                </FormLayoutColumns>
                                <FormLayoutColumns>
                                    <FieldBlock label={textBillingInfo(`field.${BillingInfoField.BUSINESS_ID}`)} required>
                                        <InputText
                                            placeholder={textBillingInfo(`placeholder.${BillingInfoField.BUSINESS_ID}`)}
                                            value={billingInfo.business_id}
                                            onChange={(value) => setBillingInfo((prevState) => new BillingInfo({...prevState, [BillingInfoField.BUSINESS_ID]: value}))}
                                        />
                                    </FieldBlock>
                                    <FieldBlock label={textBillingInfo(`field.${BillingInfoField.VAT}`)} required>
                                        <InputText
                                            placeholder={textBillingInfo(`placeholder.${BillingInfoField.VAT}`)}
                                            value={billingInfo.vat}
                                            onChange={(value) => setBillingInfo((prevState) => new BillingInfo({...prevState, [BillingInfoField.VAT]: value}))}
                                        />
                                    </FieldBlock>
                                </FormLayoutColumns>
                                {isShowPaymentInfo &&
                                    <FieldBlock label={textBillingInfo(`field.${BillingInfoField.PAYMENT_TYPE}`)} required>
                                        <Select
                                            value={billingInfo.payment_type}
                                            options={BILLING_PAYMENT_TYPES.map((value) => ({value, label: textBillingInfo(`option.${BillingInfoField.PAYMENT_TYPE}.${value}`) as string}))}
                                            onChange={(option) => setBillingInfo((prevState) => new BillingInfo({...prevState, [BillingInfoField.PAYMENT_TYPE]: option?.value}))}
                                        />
                                    </FieldBlock>
                                }
                                <FormLayoutMention/>
                                <FormLayoutButtons>
                                    <ButtonSave onClick={() => setShowModalConfirm(true)} disabled={hasEmptyField()} loading={isSubmitting}/>
                                </FormLayoutButtons>
                            </FormLayoutRows>
                        </Box>
                    </ContentBlock> :
                    <ContentBlock
                        header={{
                            actions: <Button size={ButtonSize.SMALL} style={ButtonStyle.PRIMARY_MIDNIGHT_LIGHT} onClick={() => setShowModalBillingInfoRequest(true)}>{textBillingInfo("action.ask_for_modification")}</Button>
                        }}
                    >
                        <Box spacing={BoxSpacing.LARGE}>
                            <FormLayoutRows>
                                <Alert text={textBillingInfo("message.readonly")}/>
                                <FormLayoutColumns>
                                    <FieldBlock label={textBillingInfo(`field.${BillingInfoField.COMPANY_NAME}`)}>
                                        {billingInfo.company_name || "-"}
                                    </FieldBlock>
                                </FormLayoutColumns>
                                <FormLayoutColumns>
                                    <FieldBlock label={textBillingInfo(`field.${BillingInfoField.CONTACT_FIRSTNAME}`)}>
                                        {billingInfo.contact_firstname || "-"}
                                    </FieldBlock>
                                    <FieldBlock label={textBillingInfo(`field.${BillingInfoField.CONTACT_LASTNAME}`)}>
                                        {billingInfo.contact_lastname || "-"}
                                    </FieldBlock>
                                </FormLayoutColumns>
                                <FormLayoutColumns>
                                    <FieldBlock label={textBillingInfo(`field.${BillingInfoField.ADDRESS}`)}>
                                        {billingInfo.address || "-"}
                                    </FieldBlock>
                                    <FieldBlock label={textBillingInfo(`field.${BillingInfoField.POSTAL_CODE}`)}>
                                        {billingInfo.postal_code || "-"}
                                    </FieldBlock>
                                </FormLayoutColumns>
                                <FormLayoutColumns>
                                    <FieldBlock label={textBillingInfo(`field.${BillingInfoField.CITY}`)}>
                                        {billingInfo.city || "-"}
                                    </FieldBlock>
                                    <FieldBlock label={textBillingInfo(`field.${BillingInfoField.COUNTRY}`)}>
                                        {currentCountry?.name || "-"}
                                    </FieldBlock>
                                </FormLayoutColumns>
                                <FormLayoutColumns>
                                    <FieldBlock label={textBillingInfo(`field.${BillingInfoField.BUSINESS_ID}`)}>
                                        {billingInfo.business_id || "-"}
                                    </FieldBlock>
                                    <FieldBlock label={textBillingInfo(`field.${BillingInfoField.VAT}`)}>
                                        {billingInfo.vat || "-"}
                                    </FieldBlock>
                                </FormLayoutColumns>
                                <FormLayoutColumns>
                                    <FieldBlock label={textBillingInfo(`field.${BillingInfoField.PAYMENT_TYPE}`)}>
                                        {textBillingInfo(`option.${BillingInfoField.PAYMENT_TYPE}.${billingInfo.payment_type}`)}
                                    </FieldBlock>
                                </FormLayoutColumns>
                            </FormLayoutRows>
                        </Box>
                    </ContentBlock>
                }
            </Loadable>
            <ModalConfirmMessage
                active={isShowModalConfirm}
                cancel={{onClick: () => setShowModalConfirm(false), children: textBillingInfo("action.verify")}}
                confirm={{onClick: handleSubmit, children: textBillingInfo("action.confirm"), loading: isSubmitting}}
                message={textBillingInfo("message.submit_confirmation")}
            />
            <ModalNew active={isShowModalSuccess}>
                <ModalContent>
                    <FormLayoutRows>
                        <ModalDescription alignment={ModalDescriptionAlignment.CENTER}>
                            {textBillingInfo("message.submit_success")}
                        </ModalDescription>
                        <FormLayoutButtons>
                            <Button size={ButtonSize.BIG} onClick={() => window.location.reload()}>
                                {textCommon(Action.CLOSE.labelKey)}
                            </Button>
                        </FormLayoutButtons>
                    </FormLayoutRows>
                </ModalContent>
            </ModalNew>
            <ModalBillingInfoRequest active={isShowModalBillingInfoRequest} account={loggedAccount} onClose={() => setShowModalBillingInfoRequest(false)}/>
        </>
    );
};

export default FormChangeBillingInfo;
