import {Rest} from "./Rest";
import {News} from "../model/portal/News";
import {PORTAL} from "../../../utils/Portal";
import {Locale} from "../../utils/Locale";
import {PortalEvent} from "../model/portal/PortalEvent";
import {Alerting} from "../model/portal/Alerting";
import {ContactRequest} from "../model/account/ContactRequest";
import {Origin} from "../model/Origin";
import {EmailNotificationRequest} from "../model/email/EmailNotificationRequest";
import {EmailNotificationType} from "../model/email/EmailNotificationType";
import {PortalSetting} from "../model/portal-setting/PortalSetting";

export class RestPortal extends Rest {
    rootPath = "/frontend-api";

    getSetting(): Promise<PortalSetting> {
        return this._client.get(`${this.rootPath}/portal-setting?service=${PORTAL.origin.service}&locale=${Locale.getCurrentLocale()}`, PortalSetting) as Promise<PortalSetting>;
    }

    getNews(): Promise<News[]> {
        return this._client.get(`${this.rootPath}/news?service=${PORTAL.origin.service}&locale=${Locale.getCurrentLocale()}`, News) as Promise<News[]>;
    }

    getAlerting(): Promise<Alerting> {
        const params = {
            service: PORTAL.origin.service,
            locale: Locale.getCurrentLocale()
        };
        return this._client.get(`${this.rootPath}/alerting`, Alerting, {queryParams: params}) as Promise<Alerting>;
    }

    pushEvent<T extends PortalEvent>(event: T) {
        event.service = PORTAL.origin.service;
        return this._client.post(`${this.rootPath}/event`, event);
    }

    sendContactMessage(topic: string, message: string, tags: string[] = []): Promise<void> {
        const request = ContactRequest.create(topic, message, tags);
        return this._client.post(`${this.rootPath}/contact`, request);
    }

    sendEmailNotification(type: EmailNotificationType, origin: Origin = PORTAL.origin): Promise<void> {
        const request = new EmailNotificationRequest({email_type: type});
        request.email_info.origin = origin.name;
        return this._client.post(`${this.rootPath}/email-notification`, request);
    }
}
