const ApiErrors = new Map<string, string>([
    ["account_not_found", "credentials_invalid"],
    ["already_registered", "email_already_registered"],
    ["bad_credentials", "credentials_invalid"],
    ["error_too_many_login_attempts", "login_attempts"],
    ["old_and_new_passwords_are_identical", "passwords_identical"],
    ["old_password_missing", "old_password_invalid"],
    ["old_password_invalid", "old_password_invalid"],
    ["partner_account_is_not_active", "account_inactive"],
    ["partner_is_not_active", "account_inactive"],
    ["unknown_or_expired_token", "token_invalid"]
]);

export default function getApiErrorTranslationKey (message: string) {
    const key = message && message.toLowerCase().replace(/\s+/g, "_");
    return ApiErrors.get(key) || key;
}
