import React, {FunctionComponent} from "react";
import {IncomeItem} from "../../../api/model/incomes/Income";
import {Locale} from "../../../common/utils/Locale";
import {Formatter} from "../../../common/utils/Formatter";
import {TableColumn, TableColumnStyle, TableRow} from "@sirdata/ui-lib";

type IncomeRowProps = {
    income: IncomeItem;
}

const IncomeRow: FunctionComponent<IncomeRowProps> = ({income}) => {

    const format = (value: number) => value ? new Intl.NumberFormat(Locale.getCurrentLocale() as string, {style: "currency", currency: income?.currency}).format(value) : "-";

    const getMd5Income = () => {
        if (income.minimum_hash_incomes) {
            return `${format(income.minimum_hash_incomes)} (${format(income.hash_incomes)})`;
        }
        return `${format(income.hash_incomes)}`;
    };

    return (
        <TableRow>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{Formatter.formatDate(income.month, "MM/YYYY")}</TableColumn>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>{getMd5Income()}</TableColumn>
        </TableRow>
    );
};

export default IncomeRow;
