import {ApiModel} from "../ApiModel";
import {HeaderModule} from "../../../utils/HeaderModule";

enum PortalSettingHeaderField {
    MODULES = "modules"
}

export class PortalSettingHeader extends ApiModel {
    modules: HeaderModule[] = [];

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    load(o: any): void {
        if (!o) return;
        if (typeof o === "object") {
            for (let key in o) {
                if (key === PortalSettingHeaderField.MODULES) {
                    this.modules = [];
                    if (o[key]) {
                        (o[key] as string[]).forEach((name) => {
                            const module = HeaderModule.getByName(name);
                            if (module) {
                                this.modules.push(module);
                            }
                        });
                    }
                } else {
                    this[key] = o[key];
                }
            }
        }
    }

    hasModule(headerModule: HeaderModule): boolean {
        return this.modules.some((module) => module.name === headerModule.name);
    }
}
