import React, {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../utils/constants";
import {
    Action,
    Button,
    ButtonLinkCancel,
    ButtonSize,
    ButtonStyle,
    FormLayoutButtons,
    FormLayoutRows,
    ModalContent,
    ModalDescription,
    ModalNew,
    ModalTitle,
    Textarea,
    TranslationLibFile
} from "@sirdata/ui-lib";

export type ModalPartnerInvitationProps = {
    onClose: () => void;
    active: boolean;
    onSendInvitations: (emails: string[]) => void;
};

const ModalPartnerInvitation: FunctionComponent<ModalPartnerInvitationProps> = ({onClose, active, onSendInvitations}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textAccessAccounts} = useTranslation(TranslationPortalFile.ACCESS_ACCOUNTS);

    const [emails, setEmails] = useState<string>("");

    const handleSend = () => {
        const value = emails.split(",");
        onSendInvitations(value);
    };

    useEffect(() => setEmails(""), [active]);

    return (
        <ModalNew onClose={onClose} active={active}>
            <ModalContent>
                <ModalTitle>{textAccessAccounts("send_invitations.title")}</ModalTitle>
                <FormLayoutRows>
                    <ModalDescription>
                        <span dangerouslySetInnerHTML={{__html: textAccessAccounts("send_invitations.description")}}/>
                    </ModalDescription>
                    <Textarea
                        value={emails}
                        placeholder={"abc@example.com,def@example.com,ghi@example.com"}
                        onChange={setEmails}
                        rows={5}
                    />
                    <FormLayoutButtons>
                        <ButtonLinkCancel onClick={onClose}/>
                        <Button size={ButtonSize.BIG} style={ButtonStyle.PRIMARY_MIDNIGHT} onClick={handleSend}>{textCommon(Action.SEND.labelKey)}</Button>
                    </FormLayoutButtons>
                </FormLayoutRows>
            </ModalContent>
        </ModalNew>
    );
};

export default ModalPartnerInvitation;
