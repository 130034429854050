import React, {FunctionComponent} from "react";
import {Button, ButtonSize, ButtonStyle} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../../utils/constants";

type ButtonUpdateProps = {
    onClick: () => void;
    disabled?: boolean;
};

const ButtonUpdate: FunctionComponent<ButtonUpdateProps> = ({onClick, disabled}) => {
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);

    return (
        <Button
            size={ButtonSize.MEDIUM}
            style={ButtonStyle.PRIMARY_MIDNIGHT}
            onClick={onClick}
            disabled={disabled}
        >
            {t("buttons.update")}
        </Button>
    );
};

export default ButtonUpdate;
