import {ApiModel} from "../ApiModel";
import {PortalSettingFooterItem} from "./PortalSettingFooterItem";

export class PortalSettingFooter extends ApiModel {
    legal_notice: boolean = false;
    cookies_settings: boolean = false;
    items: PortalSettingFooterItem[] = [];

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }
}
