import {ApiModel} from "../ApiModel";
import {UserNotificationType} from "./UserNotificationType";
import {UserNotificationContent} from "./UserNotificationContent";

export class UserNotification extends ApiModel {
    id: number = 0;
    type: UserNotificationType = UserNotificationType.DEFAULT;
    service: string = "";
    dispatch_date: string = "";
    is_read: boolean = false;
    private _content: UserNotificationContent = new UserNotificationContent();

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    set content(content: UserNotificationContent) {
        this._content = new UserNotificationContent(content);
    }

    get content(): UserNotificationContent {
        return this._content;
    }
}
