import {Origin} from "../common/api/model/Origin";
import {pathHome} from "../common/utils/constants";
import {pathRegister, pathRegisterBilling, pathRegisterInfo} from "./constants";

enum OnboardingHeardAboutGroup {
    RECOMMENDATION = "recommendation",
    RESEARCH = "research"
}

enum OnboardingHeardAbout {
    EDITOR = "editor",
    ADVERTISING_AGENCY = "advertising_agency",
    WEB_AGENCY = "web_agency",
    CONSULTANT = "consultant",
    OUR_TEAMS = "our_teams",
    WEB = "web",
    WEBINAR_FAIR = "webinar_fair",
    OUR_CMP = "our_cmp",
    OTHER = "other"
}

type OnboardingStep = {
    path: string;
    skippable?: boolean;
}

const STEPS_DEFAULT: OnboardingStep[] = [
    {path: pathRegister},
    {path: pathRegisterInfo}
];

const STEPS_CMP: OnboardingStep[] = [
    {path: pathRegister},
    {path: pathRegisterInfo},
    {path: pathRegisterBilling}
];

const STEPS_HELPER: OnboardingStep[] = [
    {path: pathRegister},
    {path: pathRegisterInfo},
    {path: pathRegisterBilling}
];

const STEPS_GTM_SERVER_SIDE: OnboardingStep[] = [
    {path: pathRegister},
    {path: pathRegisterInfo},
    {path: pathRegisterBilling}
];

const CMP_HEARD_ABOUT_LIST = new Map([
    [OnboardingHeardAboutGroup.RECOMMENDATION, [OnboardingHeardAbout.EDITOR, OnboardingHeardAbout.ADVERTISING_AGENCY, OnboardingHeardAbout.WEB_AGENCY, OnboardingHeardAbout.CONSULTANT, OnboardingHeardAbout.OUR_TEAMS]],
    [OnboardingHeardAboutGroup.RESEARCH, [OnboardingHeardAbout.WEB, OnboardingHeardAbout.OUR_CMP, OnboardingHeardAbout.WEBINAR_FAIR, OnboardingHeardAbout.OTHER]]
]);

const HELPER_HEARD_ABOUT_LIST = new Map([
    [OnboardingHeardAboutGroup.RECOMMENDATION, [OnboardingHeardAbout.EDITOR, OnboardingHeardAbout.ADVERTISING_AGENCY, OnboardingHeardAbout.WEB_AGENCY, OnboardingHeardAbout.CONSULTANT, OnboardingHeardAbout.OUR_TEAMS]],
    [OnboardingHeardAboutGroup.RESEARCH, [OnboardingHeardAbout.WEB, OnboardingHeardAbout.WEBINAR_FAIR, OnboardingHeardAbout.OTHER]]
]);

const GTM_SERVER_SIDE_HEARD_ABOUT_LIST = new Map([
    [OnboardingHeardAboutGroup.RECOMMENDATION, [OnboardingHeardAbout.EDITOR, OnboardingHeardAbout.ADVERTISING_AGENCY, OnboardingHeardAbout.WEB_AGENCY, OnboardingHeardAbout.CONSULTANT, OnboardingHeardAbout.OUR_TEAMS]],
    [OnboardingHeardAboutGroup.RESEARCH, [OnboardingHeardAbout.WEB, OnboardingHeardAbout.WEBINAR_FAIR, OnboardingHeardAbout.OTHER]]
]);

export class Onboarding {
    static ACCOUNT: Onboarding = new Onboarding(Origin.ACCOUNT);
    static AUDIENCE: Onboarding = new Onboarding(Origin.AUDIENCE).withOrganizationSupport();
    static CMP: Onboarding = new Onboarding(Origin.CMP, STEPS_CMP).withHeardAboutList(CMP_HEARD_ABOUT_LIST);
    static CUSTOMER: Onboarding = new Onboarding(Origin.CUSTOMER);
    static GTM_SERVER_SIDE: Onboarding = new Onboarding(Origin.GTM_SERVER_SIDE, STEPS_GTM_SERVER_SIDE).withHeardAboutList(GTM_SERVER_SIDE_HEARD_ABOUT_LIST);
    static HELPER: Onboarding = new Onboarding(Origin.HELPER, STEPS_HELPER).withHeardAboutList(HELPER_HEARD_ABOUT_LIST);

    origin: Origin = Origin.ACCOUNT;
    steps: OnboardingStep[] = [];
    heard_about_list?: Map<OnboardingHeardAboutGroup, OnboardingHeardAbout[]>;
    shouldCreateOrganization: boolean = false;

    constructor(origin: Origin, steps: OnboardingStep[] = STEPS_DEFAULT) {
        this.origin = origin;
        this.steps = steps;
    }

    static values(): Onboarding[] {
        return [
            Onboarding.ACCOUNT,
            Onboarding.AUDIENCE,
            Onboarding.CMP,
            Onboarding.CUSTOMER,
            Onboarding.GTM_SERVER_SIDE,
            Onboarding.HELPER
        ];
    }

    static forOrigin(origin?: Origin): Onboarding | undefined {
        return this.values().find((it) => it.origin.name === origin?.name);
    }

    withHeardAboutList(heard_about_list: Map<OnboardingHeardAboutGroup, OnboardingHeardAbout[]>) {
        this.heard_about_list = heard_about_list;
        return this;
    }

    withOrganizationSupport() {
        this.shouldCreateOrganization = true;
        return this;
    }

    getTotalSteps(): number {
        return this.steps.length;
    }

    getCurrentStep(path: string): number {
        return this.steps.findIndex((step) => step.path === path) + 1;
    }

    getNextStepPath(path: string): string {
        const found = this.steps.findIndex((step) => step.path === path);

        if (found >= 0 && !!this.steps[found + 1]) {
            return this.steps[found + 1].path;
        }

        return pathHome;
    }

    getPrevStepPath(path: string): string {
        const found = this.steps.findIndex((step) => step.path === path);

        if (found > 0) {
            return this.steps[found - 1].path;
        }

        return pathHome;
    }

    isOriginStepAllowed(path: string): boolean {
        return this.steps.map((it) => it.path).includes(path);
    }

    isStepSkippable(path: string): boolean {
        return !!this.steps.find((it) => it.path === path)?.skippable;
    }

    isLastStep(path: string): boolean {
        const total = this.getTotalSteps();
        const current = this.getCurrentStep(path);

        return total === current;
    }
}
