import {ApiModel} from "../ApiModel";

export class UserNotificationContent extends ApiModel {
    title: string = "";
    subtitle: string | undefined;
    text: string = "";
    image: string | undefined;
    url: string | undefined;
    call_to_action: string | undefined;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    isUrlExternal(): boolean {
        if (!this.url || this.url.startsWith("/")) {
            return false;
        }

        return !this.url.includes(window.location.host);
    }

    getUrlPathname(): string {
        if (!this.url) return "";
        if (!this.url.startsWith("/")) {
            const url = new URL(this.url);
            return `${url.pathname}${url.search}`;
        }
        return this.url;
    }
}
