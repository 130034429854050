import {ApiModel} from "../ApiModel";
import {PortalSettingHeader} from "./PortalSettingHeader";
import {PortalSettingFooter} from "./PortalSettingFooter";
import {Theme} from "../../../utils/Theme";

enum PortalSettingField {
    HEADER = "header",
    FOOTER = "footer",
    THEME = "theme"
}

export class PortalSetting extends ApiModel {
    header: PortalSettingHeader = new PortalSettingHeader();
    footer: PortalSettingFooter = new PortalSettingFooter();
    theme: Theme = Theme.SIRDATA_MIDNIGHT;

    constructor(o?: {}) {
        super(o);
        this.load(o);
        this.loadObject(PortalSettingField.HEADER, PortalSettingHeader);
        this.loadObject(PortalSettingField.FOOTER, PortalSettingFooter);
    }

    load(o: any): void {
        if (!o) return;
        if (typeof o === "object") {
            for (let key in o) {
                if (key === PortalSettingField.THEME && typeof o[key] === "string") {
                    this.theme = Theme.getByName(o[key]);
                } else {
                    this[key] = o[key];
                }
            }
        }
    }

    hasThemeWhite(): boolean {
        return this.theme === Theme.SIRDATA_WHITE;
    }
}
