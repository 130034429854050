import React, {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import clsx from "clsx";
import {Action, ActionsMenu, IconTooltip, Loadable, TranslationLibFile} from "@sirdata/ui-lib";
import {MainHeaderNotificationMenuItem} from "./index";
import {TranslationCommonFile} from "../../utils/constants";
import {UserNotification} from "../../api/model/notification/UserNotification";
import {UIEventManager} from "../../utils/UIEventManager";

export type MainHeaderNotificationMenuProps = {
    items: UserNotification[];
    loading: boolean;
    onMarkAllAsRead: () => void;
    onUpdateItem: () => void;
}

const MainHeaderNotificationMenu: FunctionComponent<MainHeaderNotificationMenuProps> = ({items, loading, onMarkAllAsRead, onUpdateItem}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textMain} = useTranslation(TranslationCommonFile.MAIN);
    const [isActive, setActive] = useState<boolean>(false);
    const [onClose, setOnClose] = useState<() => void>(() => {});

    const handleClose = () => {
        setActive(false);
        onClose();
    };

    useEffect(() => {
        UIEventManager.addListener(MainHeaderNotificationMenuUiEvent, (onClose: () => void) => {
            setActive(true);
            setOnClose(() => onClose);
        });
        return () => UIEventManager.removeAllListeners(MainHeaderNotificationMenuUiEvent);
    }, [items]);

    return (
        <div className={clsx("main-header__notification-menu", {"main-header__notification-menu--active": isActive})}>
            <div className="main-header__notification-menu__overlay"/>
            <div className="main-header__notification-menu__content">
                <div className="main-header__notification-menu__header">
                    <span>{textMain("menu.notifications")}</span>
                    <div className="main-header__notification-menu__header__actions">
                        <ActionsMenu
                            iconTooltip={{
                                icon: {...Action.MORE.icon, cssClass: "main-header__notification-menu__header__actions__icon"},
                                text: textCommon(Action.MORE.labelKey)
                            }}
                            items={[
                                {label: textMain("menu.notification.mark_all_as_read"), onClick: onMarkAllAsRead}
                            ]}
                        />
                        <IconTooltip
                            icon={{
                                ...Action.CLOSE.icon,
                                cssClass: "main-header__notification-menu__header__actions__icon",
                                onClick: handleClose
                            }}
                            text={textMain("menu.notification.close")}
                        />
                    </div>
                </div>
                <ul className="main-header__notification-menu__items">
                    <Loadable loading={loading}>
                        {
                            !!items?.length ?
                                items.map((notification) =>
                                    <MainHeaderNotificationMenuItem
                                        key={notification.id}
                                        notification={notification}
                                        onUpdate={onUpdateItem}
                                    />
                                ) :
                                <li className="main-header__notification-menu__item">
                                    <div className="main-header__notification-menu__item__content">
                                        <div className="main-header__notification-menu__item__content__text">
                                            {textMain("menu.notification.no_notification")}
                                        </div>
                                    </div>
                                </li>
                        }
                    </Loadable>
                </ul>
            </div>
        </div>
    );
};

export default MainHeaderNotificationMenu;
export const MainHeaderNotificationMenuUiEvent = "MainHeaderNotificationMenu";
