import {Origin} from "./model/Origin";
import {IconProps, MenuItem} from "@sirdata/ui-lib";

export type PortalConfigSearchOptions = {
    titleTranslationKey: string;
    path: string;
    alternateLink?: {
        icon: string;
        path: string;
        label: string;
    };
}

export type PortalConfigShortcuts = {
    target: string | (() => void);
    icon: IconProps;
    label: string;
}

/*
* Labels for portal-specific items must be defined in translation.json, in the node "footer"
*/
export type PortalConfigFooter = {
    legalNotice?: boolean;
    cookiesSettings?: boolean;
    items?: {
        label: string;
        path: string;
    }[];
}

export class PortalConfig {
    origin: Origin;
    defaultPath: string;
    menuItems: MenuItem[] = [];
    shortcutMenuItems: MenuItem[] = [];
    searchOptions: PortalConfigSearchOptions = {
        titleTranslationKey: "",
        path: "",
        alternateLink: {
            icon: "",
            path: "",
            label: ""
        }
    };
    shortcuts: PortalConfigShortcuts[] = [];

    constructor(origin: Origin, defaultPath: string) {
        this.origin = origin;
        this.defaultPath = defaultPath;
    }

    withMenuItems(menuItems: MenuItem[]): PortalConfig {
        this.menuItems = menuItems;
        return this;
    }

    withShortcutMenuItems(shortcutMenuItems: MenuItem[]): PortalConfig {
        this.shortcutMenuItems = shortcutMenuItems;
        return this;
    }

    withSearchOptions(searchOptions: PortalConfigSearchOptions): PortalConfig {
        this.searchOptions = searchOptions;
        return this;
    }

    withShortcuts(shortcuts: PortalConfigShortcuts[]): PortalConfig {
        this.shortcuts = shortcuts;
        return this;
    }

    get isInternal(): boolean {
        return this.origin.isInternal();
    }

    getMenuItemForPath(path: string): MenuItem | undefined {
        return this.menuItems.find((it) => path.match(it.path) || (it.items && it.items.length && !!it.items.find((it) => path.match(it.path))));
    }
}
