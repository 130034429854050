import {ApiModel} from "../ApiModel";
import {Model} from "../../interface/Model";

export class PartnerAuthTmpToken extends ApiModel implements Model {
    token: string = "";

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }
}
