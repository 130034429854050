import React, {FormEvent, useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import validate from "validate.js";
import {AxiosError} from "axios";

import {session} from "../../api/ApiSession";
import getApiErrorTranslationKey from "../../api/model/ApiErrors";
import {pathRegister, TranslationPortalFile} from "../../utils/constants";
import {pathHome, pathLogin, sirdataDataPrivacyPolicyUrl} from "../../common/utils/constants";
import {Origin} from "../../common/api/model/Origin";
import {
    AlertSeverity,
    Box,
    BoxSpacing,
    Button,
    ButtonSize,
    ContentBlock,
    FieldBlock,
    FlexContent,
    FlexContentAlignment,
    FlexContentDirection,
    FlexContentSpacing,
    Form,
    FormLayoutButtons,
    FormLayoutRows,
    FormValidationType,
    InputCheckbox,
    InputEmail,
    InputPassword,
    Paragraph,
    ParagraphAlignment
} from "@sirdata/ui-lib";
import {FormSchema, FormState, LogoSirdataOnboarding} from "../../component/snippet";
import {getTermsOfUseUrlForOrigin, sirdataDataAcquisitionBarterAgreementUrl} from "../../utils/helper";
import useRegister from "../../utils/hooks/useRegister";
import {FormLayoutMessage} from "../../common/component/snippet";

function Register() {
    const location = useLocation();
    const navigate = useNavigate();
    const [registerer, {setRegister, goToNext}] = useRegister(pathRegister);
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const {t: textOnboarding} = useTranslation(TranslationPortalFile.ONBOARDING);

    const [formState, setFormState] = useState<FormState>({isValid: false, errors: {}});
    const [credentials, setCredentials] = useState({email: "", password: ""});
    const [cgu, setCgu] = useState(false);
    const [cguAccount, setCguAccount] = useState(false);
    const [apiError, setApiError] = useState("");
    const [isLoading, setLoading] = useState(false);
    const [isShowErrors, setShowErrors] = useState(false);

    useEffect(() => {
        session.saveOrigin(location.search, true);
        session.saveOnboarding();
    }, [location.search]);

    useEffect(() => {
        if (session) {
            session.isLogged()
                .then((logged) => {
                    if (logged) {
                        if (registerer.isRegistering) {
                            goToNext();
                        } else {
                            navigate(pathHome);
                        }
                    }
                })
                .catch(() => {});
        }
    }, [navigate, registerer.isRegistering, goToNext]);

    useEffect(() => {
        setApiError("");
        const errors = validate(credentials, FormSchema);
        setFormState({isValid: !errors, errors: errors || {}});
    }, [credentials]);

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();

        if (!formState.isValid) {
            setShowErrors(true);
            return;
        }
        setLoading(true);
        try {
            await session.register(credentials.email, credentials.password);
            await session.setLicenseAfterRegister();
            setRegister();
            navigate(session.onboarding.getNextStepPath(pathRegister));
        } catch (e) {
            setApiError(getApiErrorTranslationKey((e as AxiosError).message));
            setLoading(false);
        }
    };

    const hasCgu = ![Origin.ACCOUNT.name, Origin.CUSTOMER.name, Origin.GTM_SERVER_SIDE.name].includes(session.origin.name);

    return (
        <div className="wrapper onboarding">
            <div className="register-page">
                <div className="register-form">
                    <div className="main-logo">
                        <LogoSirdataOnboarding/>
                    </div>
                    <div className="h1 title" dangerouslySetInnerHTML={{__html: textOnboarding("register.title", {step: session.onboarding.getCurrentStep(pathRegister), steps: session.onboarding.getTotalSteps()})}}/>
                    <div className="description">{textOnboarding("register.description." + session.origin.name)}</div>
                    <ContentBlock cssClass="register-form__block">
                        <Box spacing={BoxSpacing.XLARGE}>
                            <Form onSubmit={handleSubmit} validationType={FormValidationType.NONE}>
                                <FormLayoutRows>
                                    <FormLayoutRows spacing={FlexContentSpacing.XSMALL}>
                                        <FormLayoutRows spacing={FlexContentSpacing.SMALL}>
                                            <FieldBlock content={{direction: FlexContentDirection.COLUMN}}>
                                                <InputEmail
                                                    placeholder={textOnboarding("form.field.email")}
                                                    value={credentials.email}
                                                    onChange={(value) => setCredentials((prevState) => ({...prevState, email: value}))}
                                                    autoFocus
                                                />
                                                {(isShowErrors && formState.errors.email) &&
                                                    <FormLayoutMessage message={t(`error.${formState.errors.email}`)} severity={AlertSeverity.DANGER}/>
                                                }
                                            </FieldBlock>
                                            <FieldBlock content={{direction: FlexContentDirection.COLUMN}}>
                                                <InputPassword
                                                    placeholder={textOnboarding("form.placeholder.password")}
                                                    value={credentials.password}
                                                    onChange={(value) => setCredentials((prevState) => ({...prevState, password: value}))}
                                                />
                                                {(isShowErrors && formState.errors.password) &&
                                                    <FormLayoutMessage message={t(`error.${formState.errors.password}`)} severity={AlertSeverity.DANGER}/>
                                                }
                                            </FieldBlock>
                                        </FormLayoutRows>
                                        <FlexContent direction={FlexContentDirection.COLUMN}>
                                            <div className="checkbox-field">
                                                <InputCheckbox
                                                    id={"accept_account_cgu"}
                                                    checked={cguAccount}
                                                    onChange={setCguAccount}
                                                />
                                                <label htmlFor="accept_account_cgu">
                                                    <span dangerouslySetInnerHTML={{__html: textOnboarding("register.cgu.account", {termsOfUseUrl: getTermsOfUseUrlForOrigin(Origin.ACCOUNT)})}}/>
                                                </label>
                                            </div>
                                            {hasCgu &&
                                                <div className="checkbox-field">
                                                    <InputCheckbox
                                                        id={"accept_cgu"}
                                                        checked={cgu}
                                                        onChange={setCgu}
                                                    />
                                                    <label htmlFor="accept_cgu">
                                                        <span
                                                            dangerouslySetInnerHTML={{
                                                                __html: textOnboarding(
                                                                    `register.cgu.${session.origin.name}${session.premium ? "_premium" : ""}`,
                                                                    {
                                                                        termsOfUseUrl: getTermsOfUseUrlForOrigin(session.origin),
                                                                        dataContractUrl: sirdataDataAcquisitionBarterAgreementUrl,
                                                                        privacyPolicyUrl: sirdataDataPrivacyPolicyUrl
                                                                    })
                                                            }}
                                                        />
                                                    </label>
                                                </div>
                                            }
                                        </FlexContent>
                                    </FormLayoutRows>
                                    <FlexContent direction={FlexContentDirection.COLUMN} alignment={FlexContentAlignment.CENTER} spacing={FlexContentSpacing.XSMALL}>
                                        <FormLayoutButtons>
                                            <Button size={ButtonSize.BIG} disabled={!credentials.email || !credentials.password || !cguAccount || (hasCgu && !cgu)} loading={isLoading}>{textOnboarding("form.button.register")}</Button>
                                        </FormLayoutButtons>
                                        {apiError &&
                                            <FormLayoutMessage message={t(`error.${apiError}`, t("error.default"))} severity={AlertSeverity.DANGER}/>
                                        }
                                    </FlexContent>
                                    <Paragraph alignment={ParagraphAlignment.CENTER}>
                                        {textOnboarding("already_registered")} <a href={pathLogin + location?.search}>{textOnboarding("connect_link")}</a>
                                    </Paragraph>
                                </FormLayoutRows>
                            </Form>
                        </Box>
                    </ContentBlock>
                </div>
            </div>
        </div>
    );
}

export default Register;
