export enum FormErrorMessage {
    EMAIL_INVALID = "email_invalid",
    EMAIL_MISMATCH = "email_mismatch",
    PASSWORD_MISMATCH = "password_mismatch",
    PASSWORD_LENGTH = "password_too_short",
    PHONE_INVALID = "phone_invalid",
}

export const FormSchema = {
    email: {
        email: {
            message: () => `^${FormErrorMessage.EMAIL_INVALID}`
        }
    },
    confirmEmail: {
        equality: {
            attribute: "email",
            message: () => `^${FormErrorMessage.EMAIL_MISMATCH}`,
            comparator: (v1: string, v2: string) => (v1 === v2)
        }
    },
    password: {
        length: {
            minimum: 6,
            message: () => `^${FormErrorMessage.PASSWORD_LENGTH}`
        }
    },
    confirmPassword: {
        equality: {
            attribute: "password",
            message: () => `^${FormErrorMessage.PASSWORD_MISMATCH}`,
            comparator: (v1: string, v2: string) => (v1 === v2)
        }
    },
    phone: {
        format: (value: string, attributes: any) => {
            if (!attributes.phone) return null;
            return ({
                pattern: /^[+]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/,
                message: () => `^${FormErrorMessage.PHONE_INVALID}`
            });
        }
    }
};
