import {ApiModel} from "../ApiModel";

export enum PortalEventRelatedType {
    PARTNER = "partner",
    PARTNER_ACCOUNT = "partner_account"
}

export class PortalEvent extends ApiModel {
    service: string = "";
    type: string = "";
    related_id?: string;
    related_type?: string;
    data: any;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    withData(data: any): PortalEvent {
        this.data = data;
        return this;
    }

    getJsonParameters(): {} {
        return {
            service: this.service,
            type: this.type,
            related_id: this.related_id,
            related_type: this.related_type,
            data: this.data
        };
    }
}
