import {ApiModel} from "../ApiModel";

export class News extends ApiModel {
    title: string = "";
    url: string = "";
    pubDate: string = "";
    language: string = "";

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }
}
