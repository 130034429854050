import cookie from "react-cookies";

export class Cookie {
    static write(name: string, value: string, expirationDate?: Date) {
        cookie.save(name, value, {path: "/", expires: expirationDate});
    }

    static read(name: string): string {
        return cookie.load(name);
    }

    static remove(name: string) {
        cookie.remove(name, {path: "/"});
    }
}
