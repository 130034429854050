import React, {FunctionComponent} from "react";
import {AccountInvitation} from "../../../api/model/invitation/AccountInvitation";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../../utils/constants";
import {Action, ActionsMenu, TableActionColumn, TableColumn, TableColumnStyle, TableRow, TranslationLibFile} from "@sirdata/ui-lib";
import {Formatter} from "../../../common/utils/Formatter";

type InvitationRowProps = {
    invitation: AccountInvitation;
    onResend: (email: string) => void;
    onCancel: (email: string) => void;
}

const InvitationRow: FunctionComponent<InvitationRowProps> = ({invitation, onResend, onCancel}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textAccessAccounts} = useTranslation(TranslationPortalFile.ACCESS_ACCOUNTS);

    return (
        <TableRow>
            <TableColumn styles={TableColumnStyle.FIXED_WIDTH}>{invitation.email}</TableColumn>
            <TableColumn styles={[TableColumnStyle.HIDE_SCREEN_SMALL, TableColumnStyle.FIXED_WIDTH]}>{invitation.sent_by}</TableColumn>
            <TableColumn styles={TableColumnStyle.HIDE_SCREEN_MEDIUM}>{Formatter.formatDate(invitation.creation)}</TableColumn>
            <TableColumn styles={TableColumnStyle.HIDE_SCREEN_MEDIUM}>{Formatter.formatDate(invitation.expiration)}</TableColumn>
            <TableActionColumn styles={TableColumnStyle.ALIGN_RIGHT}>
                <ActionsMenu
                    iconTooltip={{icon: Action.MORE.icon, text: textCommon(Action.MORE.labelKey)}}
                    items={[
                        {label: textAccessAccounts("list.action.resend_invitation"), onClick: () => onResend(invitation.email)},
                        {label: textAccessAccounts("list.action.cancel_invitation"), onClick: () => onCancel(invitation.email), separator: true}
                    ]}
                />
            </TableActionColumn>
        </TableRow>
    );
};

export default InvitationRow;
