export enum Currency {
    EURO = "EUR",
    USD = "USD",
    GBP = "GBP",
}

type CurrencyItem = {
    currency: Currency;
    symbol: string;
    labelKey: string;
}

const CURRENCIES: CurrencyItem[] = [
    {currency: Currency.EURO, symbol: "€", labelKey: "euro"},
    {currency: Currency.GBP, symbol: "£", labelKey: "pound"},
    {currency: Currency.USD, symbol: "$", labelKey: "dollar"}
];

export function getSymbolFromCurrency(currency: Currency) {
    return CURRENCIES.find((it) => it.currency === currency)?.symbol || currency;
}
