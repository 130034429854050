import React, {FunctionComponent} from "react";
import {Action, Button, ButtonSize, TranslationLibFile} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";

type ButtonDownloadProps = {
    onClick: () => void;
};

const ButtonDownload: FunctionComponent<ButtonDownloadProps> = ({onClick}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);

    return (
        <Button
            icon={Action.DOWNLOAD.icon}
            size={ButtonSize.SMALL}
            onClick={onClick}
        >
            {textCommon(Action.DOWNLOAD.labelKey)}
        </Button>
    );
};

export default ButtonDownload;
