import {ApiModel} from "../ApiModel";
import {AlertSeverity} from "@sirdata/ui-lib";

export class Alerting extends ApiModel {
    text: string = "";
    severity?: AlertSeverity;

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }
}
