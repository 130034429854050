import React, {FunctionComponent, useEffect, useState} from "react";
import {session} from "../../../api/ApiSession";
import {LoggedAccount} from "../../api/interface/LoggedAccount";

export type RestrictedContentProps = {
    allowedTo?: string | undefined;
    children: React.ReactNode;
};

const RestrictedContent: FunctionComponent<RestrictedContentProps> = ({allowedTo, children}) => {
    const [account, setAccount] = useState<LoggedAccount>();

    useEffect(() => {
        (async function () {
            try {
                setAccount(await session.getAccount());
            } catch (e) {
            }
        })();
    }, []);

    return (
        <>
            {(!allowedTo || account?.hasAuthorization(allowedTo)) && children}
        </>
    );
};

export default RestrictedContent;
