import {Rest} from "../../common/api/rest/Rest";
import {BillingInfo} from "../model/billing/BillingInfo";
import {MonthlyInvoices} from "../model/billing/MonthlyInvoices";
import {Invoice} from "../model/billing/Invoice";

export class RestBilling extends Rest {
    getInvoices(start: string, end: string): Promise<MonthlyInvoices[]> {
        return this._client.get(`/partner/invoice?start=${start}&end=${end}`, MonthlyInvoices) as Promise<MonthlyInvoices[]>;
    }

    getInvoiceFile(invoice: Invoice): Promise<Blob> { // TODO Check file type
        return this._client.get(`/partner/invoice/${invoice.id}`, undefined, {responseType: "blob"}) as Promise<Blob>;
    }

    getPartnerBillingInfo(): Promise<BillingInfo> {
        return this._client.get("/partner/billing-info", BillingInfo) as Promise<BillingInfo>;
    }

    updatePartnerBillingInfo(billingInfo: BillingInfo): Promise<BillingInfo> {
        return this._client.post("/partner/billing-info", billingInfo, BillingInfo) as Promise<BillingInfo>;
    }
}
