import {ApiModel} from "../ApiModel";
import {License} from "./License";

export class PartnerLicense extends ApiModel {
    active: boolean = false;
    expires_at?: string;
    partner_info?: Map<string, any>;
    private _license: License = new License();

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    set license(license: License) {
        this._license = new License(license);
    }

    get license(): License {
        return this._license;
    }
}
