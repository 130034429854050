import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {session} from "../../../api/ApiSession";
import getApiErrorTranslationKey from "../../../api/model/ApiErrors";
import {TranslationPortalFile} from "../../../utils/constants";
import {Alert, AlertItem, AlertSeverity, Box, BoxSpacing, ButtonLinkCancel, ButtonSave, FieldBlock, FormLayoutButtons, FormLayoutMention, FormLayoutRows, FormLayoutTitle, SelectAutocomplete} from "@sirdata/ui-lib";
import {Country, CountryCode} from "../../../common/api/model/Country";
import {PartnerAccount} from "../../../api/model/account/PartnerAccount";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {ButtonUpdate} from "../index";
import {Account} from "../../../common/api/model/account/Account";
import {AccountType} from "../../../common/api/model/account/AccountType";

const FormChangePartnerInfo = () => {
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const {t: textInformation} = useTranslation(TranslationPortalFile.INFORMATION);

    const [loggedAccount, setLoggedAccount] = useState<Account>();
    const [partnerAccount, setPartnerAccount] = useState<PartnerAccount>();
    const [countries, setCountries] = useState<Country[]>([]);
    const [isOpenForm, setOpenForm] = useState(false);
    const [infoForm, setInfoForm] = useState<{ company: string; country_id: number }>({
        company: "",
        country_id: CountryCode.FRANCE.id
    });
    const [message, setMessage] = useState<AlertItem>();

    const hasEmptyField = () => !infoForm.company || !infoForm.country_id;

    const handleSubmit = async () => {
        try {
            let account = await session.getPartnerAccount();
            account.load({
                partner: {
                    id: account.partner.id,
                    company: infoForm.company,
                    country_id: +infoForm.country_id
                }
            });
            await session.restPartnerAccount.update(account);

            setOpenForm(false);
            showMessage(textInformation("partner_info_form.message.submit_success"));
        } catch (e) {
            if (e instanceof ErrorResponse) {
                showMessage(t(`error.${getApiErrorTranslationKey(e.message)}`), AlertSeverity.DANGER);
            }
        }
    };

    const showMessage = (message: string, severity?: AlertSeverity) => {
        setMessage({text: message, severity: severity || AlertSeverity.SUCCESS});
        setTimeout(() => setMessage(undefined), 3000);
    };

    useEffect(() => {
        (async function () {
            try {
                const loggedAccount = await session.getAccount();
                setLoggedAccount(loggedAccount);

                const partnerAccount = await session.getPartnerAccount();
                setPartnerAccount(partnerAccount);
                setInfoForm({
                    company: partnerAccount.partner.company,
                    country_id: partnerAccount.partner.country_id
                });
                const countries = await session.getCountries();
                setCountries(countries);
            } catch (e) {
                showMessage("default", AlertSeverity.DANGER);
            }
        })();
    }, [isOpenForm]);

    const currentCountry = countries.find((country) => country.id === infoForm.country_id);

    if (!partnerAccount) return <></>;

    return (
        <Box spacing={BoxSpacing.LARGE}>
            <FormLayoutRows>
                <FormLayoutTitle>{textInformation("partner_info_form.title")}</FormLayoutTitle>
                {isOpenForm ?
                    <>
                        <FieldBlock label={textInformation("partner_info_form.field.id")}>
                            {partnerAccount.partner.id?.toString() || ""}
                        </FieldBlock>
                        <FieldBlock label={textInformation("partner_info_form.field.company")}>
                            {partnerAccount.partner.company}
                        </FieldBlock>
                        <FieldBlock label={textInformation("partner_info_form.field.country")} required>
                            <SelectAutocomplete
                                onChange={(option) => setInfoForm((prev) => ({...prev, country_id: option?.value as number}))}
                                options={countries.map((country) => ({value: country.id, label: country.name}))}
                                value={currentCountry?.id || CountryCode.FRANCE.id}
                            />
                        </FieldBlock>
                        <FormLayoutMention/>
                        <FormLayoutButtons>
                            <ButtonLinkCancel onClick={() => setOpenForm(false)}/>
                            <ButtonSave onClick={handleSubmit} disabled={hasEmptyField()}/>
                        </FormLayoutButtons>
                    </> :
                    <>
                        <FieldBlock label={textInformation("partner_info_form.field.id")}>
                            {partnerAccount.partner.id?.toString() || ""}
                        </FieldBlock>
                        <FieldBlock label={textInformation("partner_info_form.field.company")}>
                            {partnerAccount.partner.company}
                        </FieldBlock>
                        <FieldBlock label={textInformation("partner_info_form.field.country")}>
                            {countries.find(({id}) => id === partnerAccount.partner.country_id)?.name || ""}
                        </FieldBlock>
                        {loggedAccount?.type === AccountType.OWNER &&
                            <FormLayoutButtons>
                                <ButtonUpdate
                                    onClick={() => {
                                        setMessage(undefined);
                                        setOpenForm(true);
                                    }}
                                />
                            </FormLayoutButtons>
                        }
                    </>
                }
                {message && <Alert {...message}/>}
            </FormLayoutRows>
        </Box>
    );
};

export default FormChangePartnerInfo;
