import {CommonApiClient} from "../CommonApiClient";

export class Rest {
    protected _client: CommonApiClient;

    constructor(client: CommonApiClient) {
        this._client = client;
    }

    static params(path: string, params: { [key: string]: string }): string {
        for (let index in params) {
            let search = "{" + index + "}";
            path = path.replace(search, params[index]);
        }
        return path;
    }

    static encodeQueryData(data: { [key: string]: any }): string {
        let ret = [];
        for (let d in data)
            ret.push(encodeURIComponent(d) + "=" + encodeURIComponent(data[d]));
        return ret.join("&");
    }
}
