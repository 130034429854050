import {AlertSeverity} from "@sirdata/ui-lib";
import React, {FunctionComponent} from "react";
import clsx from "clsx";

type FormLayoutMessageProps = {
    message: string;
    severity: AlertSeverity;
    small?: boolean;
}

const FormLayoutMessage: FunctionComponent<FormLayoutMessageProps> = ({message, severity, small}) => {
    const getCssClassFromSeverity = (severity: AlertSeverity) => {
        switch (severity) {
            case AlertSeverity.DANGER:
                return "form-layout__message--danger";
            case AlertSeverity.WARNING:
                return "form-layout__message--warning";
            case AlertSeverity.SUCCESS:
                return "form-layout__message--success";
            case AlertSeverity.INFO:
                return "form-layout__message--info";
            default:
                return "";
        }
    };

    return (
        <span className={clsx("form-layout__message", {"form-layout__message--small": small}, getCssClassFromSeverity(severity))} dangerouslySetInnerHTML={{__html: message}}/>
    );
};

export default FormLayoutMessage;