import React, {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {MainHeaderNotificationMenu} from "./index";
import {session} from "../../../api/ApiSession";
import {TranslationCommonFile} from "../../utils/constants";
import {UserNotification} from "../../api/model/notification/UserNotification";
import {MainHeaderItem} from "@sirdata/ui-lib";

type MainHeaderNotificationsProps = {
    active: boolean;
    onOpen: () => void;
}

const MainHeaderNotifications: FunctionComponent<MainHeaderNotificationsProps> = ({active, onOpen}) => {
    const {t: textMain} = useTranslation(TranslationCommonFile.MAIN);
    const [isLoading, setLoading] = useState(true);
    const [notifications, setNotifications] = useState<UserNotification[]>([]);

    const loadNotifications = () => {
        (async () => {
            try {
                setNotifications(await session.restUserNotifications.getHeaderNotifications());
            } catch {
            } finally {
                setLoading(false);
            }
        })();
    };

    const handleMarkAllAsRead = () => {
        (async () => {
            try {
                if (notifications?.length) {
                    for (let i = 0; i < notifications?.length; i++) {
                        const notification = notifications[i];
                        if (!notification.is_read) {
                            await session.restUserNotifications.markAsRead(notification.id);
                        }
                    }
                }
            } catch {
            } finally {
                loadNotifications();
            }
        })();
    };

    useEffect(() => {
        if (active) {
            loadNotifications();
        }
    }, [active]);

    useEffect(() => {
        loadNotifications();
    }, []);

    return (
        <MainHeaderItem
            icon={{name: "notifications"}}
            label={textMain("menu.notifications")}
            active={active}
            onOpen={onOpen}
            withChip={!!notifications?.find((it) => !it.is_read)}
        >
            <MainHeaderNotificationMenu items={notifications} loading={isLoading} onMarkAllAsRead={handleMarkAllAsRead} onUpdateItem={loadNotifications}/>
        </MainHeaderItem>
    );
};

export default MainHeaderNotifications;
