import {Rest} from "../../common/api/rest/Rest";
import {EmailInfo} from "../model/account/EmailInfo";
import {AccountInvitation} from "../model/invitation/AccountInvitation";
import {SendInvitationRequest} from "../model/invitation/SendInvitationRequest";
import {AcceptInvitationRequest} from "../model/invitation/AcceptInvitationRequest";
import {CancelInvitationRequest} from "../model/invitation/CancelInvitationRequest";
import {pathInvitationAccept} from "../../utils/constants";

export class RestInvitation extends Rest {
    async getPendingInvitations(): Promise<AccountInvitation[]> {
        return this._client.get("/auth/invitation-pending", AccountInvitation) as Promise<AccountInvitation[]>;
    }

    async send(email: string, resend?: boolean): Promise<void> {
        let emailInfo = new EmailInfo();
        emailInfo.token_link = pathInvitationAccept;

        let request = new SendInvitationRequest();
        request.email = email;
        request.email_info = emailInfo;

        let path = "/auth/invitation-send";
        if (resend) path = "/auth/invitation-resend";
        await this._client.post(path, request);
    }

    async accept(token: string, password: string): Promise<void> {
        let request = new AcceptInvitationRequest();
        request.token = token;
        request.password = password;
        await this._client.post("/auth/invitation-accept", request);
    }

    async cancel(email: string): Promise<void> {
        let request = new CancelInvitationRequest();
        request.email = email;
        await this._client.post("/auth/invitation-cancel", request);
    }
}
