import {useState} from "react";
import {MainContent, Wrapper} from "../common/component/widget";
import {Alert, AlertSeverity, Box, BoxSpacing, Color, FormLayoutRows, FormLayoutSeparator, LayoutRows, MainContentPageHeader, MainContentStyle} from "@sirdata/ui-lib";
import {FormChangeEmail, FormChangePassword} from "../component/snippet";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../utils/constants";
import {MainHeader} from "../common/component/snippet";

function Credentials() {
    const {t: textCredentials} = useTranslation(TranslationPortalFile.CREDENTIALS);
    const [message, setMessage] = useState("");

    const showMessage = (message: string) => {
        setMessage(message);
        setTimeout(() => setMessage(""), 3000);
    };

    return (
        <Wrapper>
            <MainHeader/>
            <MainContent style={MainContentStyle.NARROW_WIDTH}>
                <MainContentPageHeader
                    title={textCredentials("title")}
                    icon={{name: "lock_open", outlined: true, colorIcon: Color.CYAN}}
                />
                <LayoutRows>
                    <Box spacing={BoxSpacing.LARGE}>
                        <FormLayoutRows>
                            {message &&
                                <Alert text={message} severity={AlertSeverity.SUCCESS}/>
                            }
                            <FormChangeEmail onSuccess={showMessage}/>
                            <FormLayoutSeparator/>
                            <FormChangePassword onSuccess={showMessage}/>
                        </FormLayoutRows>
                    </Box>
                </LayoutRows>
            </MainContent>
        </Wrapper>
    );
}

export default Credentials;
