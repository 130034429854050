import React, {FunctionComponent, useEffect, useRef, useState} from "react";
import {Alert, AlertSeverity, AlertSize} from "@sirdata/ui-lib";
import {UIEventManager} from "../../utils/UIEventManager";
import clsx from "clsx";

export type MainAlertItem = {
    text: string;
    severity: AlertSeverity;
    title?: string;
    isCopiable?: boolean;
    isClosable?: boolean;
}

const DISPLAY_DURATION_IN_MILLISECONDS = 10000;

const MainAlerts: FunctionComponent = () => {
    const alertTimeoutRef: any = useRef();
    const [items, setItems] = useState<MainAlertItem[]>([]);
    const [isExpiredItems, setExpiredItems] = useState(false);

    useEffect(() => {
        UIEventManager.addListener(MainAlertUIEvent, (item: MainAlertItem) => {
            setItems((prevState) => [...prevState, item]);
            if (alertTimeoutRef.current) {
                clearTimeout(alertTimeoutRef.current);
                alertTimeoutRef.current = undefined;
            }
            alertTimeoutRef.current = setTimeout(() => {
                setExpiredItems(true);
                setTimeout(() => {
                    setItems([]);
                    setExpiredItems(false);
                }, 1500);
            }, DISPLAY_DURATION_IN_MILLISECONDS);
        });
        return () => UIEventManager.removeAllListeners(MainAlertUIEvent);
    }, []);

    const handleCloseItem = (index: number) => {
        setItems((prevState) => {
            const newItems = [...prevState];
            newItems.splice(index, 1);
            return newItems;
        });
    };

    return (
        <div className={clsx("main-alerts", {"main-alerts--expired": isExpiredItems})}>
            {items.map((item, index) => {
                const key = `main-alert-${index}`;
                return (
                    <Alert
                        key={key}
                        text={item.text}
                        severity={item.severity}
                        title={item.title}
                        isCopiable={item.isCopiable}
                        onClose={item.isClosable ? () => handleCloseItem(index) : undefined}
                        size={AlertSize.BIG}
                        cssClass={"main-alerts__item"}
                    />
                );
            })}
        </div>
    );
};

export default MainAlerts;
export const MainAlertUIEvent = "MainAlert";
