import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";
import {ApiToken} from "../../../api/model/ApiToken";
import {TranslationPortalFile} from "../../../utils/constants";
import {Action, ActionsMenu, IconTooltip, TableActionColumn, TableColumn, TableColumnStyle, TableRow, TranslationLibFile} from "@sirdata/ui-lib";
import {Formatter} from "../../../common/utils/Formatter";

type ApiTokenRowProps = {
    apiToken: ApiToken;
    onUpdate: () => void;
    onDelete: () => void;
}

const ApiTokenRow: FunctionComponent<ApiTokenRowProps> = ({apiToken, onUpdate, onDelete}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textAccessApiTokens} = useTranslation(TranslationPortalFile.ACCESS_API_TOKENS);

    return (
        <TableRow>
            <TableColumn>{apiToken.name}</TableColumn>
            <TableColumn styles={TableColumnStyle.HIDE_SCREEN_MEDIUM}>{apiToken.description}</TableColumn>
            <TableColumn styles={TableColumnStyle.HIDE_SCREEN_MEDIUM}>{Formatter.formatDate(apiToken.created_at)}</TableColumn>
            <TableActionColumn styles={TableColumnStyle.ALIGN_RIGHT}>
                <IconTooltip
                    icon={Action.EDIT.icon}
                    text={textAccessApiTokens("table.action.update_info")}
                    onClick={onUpdate}
                />
                <ActionsMenu
                    iconTooltip={{icon: Action.MORE_ACTIONS.icon, text: textCommon(Action.MORE_ACTIONS.labelKey)}}
                    items={[
                        {label: textCommon(Action.DELETE.labelKey), onClick: onDelete, critical: true}
                    ]}
                />
            </TableActionColumn>
        </TableRow>
    );
};

export default ApiTokenRow;
