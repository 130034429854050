import {PortalEvent} from "../../../common/api/model/portal/PortalEvent";

type Data = {
    value: string;
    from_origin: string;
}

export class HeardAboutEvent extends PortalEvent {
    type = "HEARD_ABOUT";
    data: Data = {
        value: "",
        from_origin: ""
    };

    withData(data: Data): HeardAboutEvent {
        this.data = data;
        return this;
    }
}