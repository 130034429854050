import React from "react";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../utils/constants";
import {MainContent, Wrapper} from "../common/component/widget";
import {MainContentPageHeader, MainContentStyle, Paragraph, ParagraphAlignment} from "@sirdata/ui-lib";
import {MainHeader} from "../common/component/snippet";

type TermsOfUseSection = {
    title: string;
    paragraphs: string[];
    noBullet?: boolean;
}

function TermsOfUse() {
    const {t: textTermsOfUse} = useTranslation(TranslationPortalFile.TERMS_OF_USE);

    return (
        <Wrapper>
            <MainHeader/>
            <MainContent style={MainContentStyle.NARROW_WIDTH}>
                <MainContentPageHeader title={textTermsOfUse("title")}/>
                <Paragraph alignment={ParagraphAlignment.JUSTIFY} withSpacing>
                    <strong><i>{textTermsOfUse("lastUpdated")}</i></strong>
                </Paragraph>
                {(textTermsOfUse("sections", {returnObjects: true}) as TermsOfUseSection[]).map(({title, paragraphs}, sectionIndex) => {
                    const sectionKey = `terms-of-use-section-${sectionIndex}`;
                    return (
                        <React.Fragment key={sectionKey}>
                            {title && <h2>{title}</h2>}
                            {paragraphs.map((item, index) =>
                                <Paragraph key={`${sectionKey}.p-${index.toString()}`} alignment={ParagraphAlignment.JUSTIFY} withSpacing>
                                    <span dangerouslySetInnerHTML={{__html: item}}/>
                                </Paragraph>
                            )}
                        </React.Fragment>
                    );
                })}
            </MainContent>
        </Wrapper>
    );
}

export default TermsOfUse;
