import {Serializable} from "../../../common/api/interface/Serializable";
import {EmailInfo} from "../account/EmailInfo";

export class SendInvitationRequest implements Serializable {
    email: string = "";
    email_info: EmailInfo = new EmailInfo();

    toJson(): string {
        return JSON.stringify(this.getJsonParameters());
    }

    getJsonParameters(): {} {
        return {
            email: this.email,
            email_info: this.email_info
        };
    }
}
