export const abbreviate = (value: string, maxLength: number): string => {
    if (value.length > maxLength) {
        return value.substring(0, maxLength).concat("...");
    }
    return value;
};

export const capitalize = (value: string): string => {
    return value.charAt(0).toUpperCase().concat(value.slice(1));
};

/**
 * Format value to an URL-compatible string replacing all non-accepted characters
 *
 * @param value
 */
export const slugify = (value: string): string => {
    return value.replaceAll(" ", "-")
        .replaceAll(/[^A-Za-z_0-9-.]+/g, "")
        .toLowerCase()
        .trim();
};

export const validateUrl = (url: string): boolean => {
    const urlRegex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)/g;
    return new RegExp(urlRegex).test(url);
};
