import {Serializable} from "../interface/Serializable";

export class ApiModel implements Serializable {
    [key: string]: any;

    load(o: any): void {
        if (!o) return;
        if (typeof o === "object") {
            for (let key in o) {
                const value = o[key];
                if (value instanceof Map) {
                    this[key] = new Map(value);
                } else {
                    this[key] = value;
                }
            }
        }
    }

    loadObject(key: string, model: new (o?: any) => ApiModel): void {
        const obj = this[key];
        if (obj) {
            this[key] = new model(obj);
        }
    }

    loadObjects(key: string, model: new (o?: any) => ApiModel): void {
        if (!this[key]) return;
        const values = Object.values(this[key]);
        this[key] = values.map((it) => new model(it!));
    }

    constructor(o?: {}) {
        this.load(o);
    }

    toJson(): string {
        return JSON.stringify(this.getJsonParameters());
    }

    getJsonParameters(): {} {
        let values = new Map<string, any>();
        for (let key in this) {
            values.set(key, this[key]);
        }
        return values;
    }
}
