import React, {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {PartnerAccount} from "../../../api/model/account/PartnerAccount";
import {TranslationPortalFile} from "../../../utils/constants";
import {Action, ActionMenuItem, ActionsMenu, Color, Icon, TableActionColumn, TableColumn, TableColumnStyle, TableRow, TranslationLibFile} from "@sirdata/ui-lib";
import {Account} from "../../../common/api/model/account/Account";
import {session} from "../../../api/ApiSession";
import {AccountType} from "../../../common/api/model/account/AccountType";

type PartnerRowProps = {
    account: PartnerAccount;
    onToggleType: (account: PartnerAccount) => void;
    onToggleStatus: (account: PartnerAccount) => void;
};

const PartnerRow: FunctionComponent<PartnerRowProps> = ({account, onToggleType, onToggleStatus}) => {
    const {t: textCommon} = useTranslation(TranslationLibFile.COMMON);
    const {t: textAccessAccounts} = useTranslation(TranslationPortalFile.ACCESS_ACCOUNTS);
    const [loggedAccount, setLoggedAccount] = useState<Account>();

    const buildActionsMenu = () => {
        let actions: ActionMenuItem[] = [];
        if (loggedAccount?.type === AccountType.OWNER && account.type !== AccountType.OWNER) {
            actions = [
                {label: textAccessAccounts(account.type === AccountType.USER ? "list.action.set_as_admin" : "list.action.set_as_user"), onClick: () => onToggleType(account)},
                {label: textAccessAccounts(account.active ? "list.action.deactivate_account" : "list.action.activate_account"), onClick: () => onToggleStatus(account), critical: true, separator: true}
            ];
        } else if (loggedAccount?.type === AccountType.ADMIN && account.type === AccountType.USER) {
            actions = [
                {label: textAccessAccounts(account.active ? "list.action.deactivate_account" : "list.action.activate_account"), onClick: () => onToggleStatus(account), critical: true}
            ];
        }

        if (!actions.length) return <></>;

        return (
            <ActionsMenu
                iconTooltip={{icon: Action.MORE.icon, text: textCommon(Action.MORE.labelKey)}}
                items={actions}
            />
        );
    };

    useEffect(() => {
        (async function () {
            try {
                const loggedAccount = await session.getAccount();
                setLoggedAccount(loggedAccount);
            } catch (e) {
            } finally {
            }
        })();
    }, []);

    return (
        <TableRow>
            <TableColumn styles={TableColumnStyle.ALIGN_CENTER}>
                <Icon colorIcon={account.active ? Color.GREEN : Color.RED} name="fiber_manual_record"/>
            </TableColumn>
            <TableColumn styles={TableColumnStyle.FIXED_WIDTH}>{account.displayed_name}</TableColumn>
            <TableColumn styles={TableColumnStyle.HIDE_SCREEN_SMALL}>{account.email}</TableColumn>
            <TableColumn styles={TableColumnStyle.HIDE_SCREEN_MEDIUM}>{textAccessAccounts("account." + account.type)}</TableColumn>
            <TableActionColumn styles={TableColumnStyle.ALIGN_RIGHT}>
                {buildActionsMenu()}
            </TableActionColumn>
        </TableRow>
    );
};

export default PartnerRow;
