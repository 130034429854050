import {ApiModel} from "../../../common/api/model/ApiModel";
import {CountryCode} from "../../../common/api/model/Country";
import {PartnerOrganization} from "./PartnerOrganization";

export class Partner extends ApiModel {
    id: number | undefined;
    company: string = "";
    creation_service: string = "";
    country_id: number = CountryCode.FRANCE.id;
    privacy_policy_url: string = "";
    private _organization: PartnerOrganization = new PartnerOrganization();

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    set organization(po: PartnerOrganization) {
        this._organization = new PartnerOrganization(po);
    }

    get organization(): PartnerOrganization {
        return this._organization;
    }
}
