import numeral from "numeral";
import {ChartOptions} from "chart.js";
import {Currency} from "./Currency";
import {Formatter} from "../common/utils/Formatter";
import {Locale} from "../common/utils/Locale";

export type GraphDataset = {
    label?: string;
    borderColor?: string;
    borderWidth?: number;
    backgroundColor: string | string[];
    pointBackgroundColor?: string;
    pointBorderColor?: string;
    pointRadius?: number;
    fill?: string;
    data: number[];
};

export type GraphData = {
    labels: string[];
    datasets: GraphDataset[];
}

export const optionsLinesDaily: ChartOptions<"line"> = {
    animation: false,
    maintainAspectRatio: false,
    elements: {
        line: {
            tension: 0.4
        },
        point: {
            pointStyle: "rectRounded",
            hoverRadius: 10
        }
    },
    scales: {
        x: {
            type: "time",
            time: {
                unit: "day",
                displayFormats: {
                    day: "DD/MM"
                }
            },
            grid: {
                display: false
            },
            border: {
                display: false
            }
        },
        y: {
            type: "linear",
            offset: false,
            ticks: {
                callback: (tickValue) => Formatter.formatNumber(tickValue as number)
            },
            min: 0,
            beginAtZero: true
        }
    },
    plugins: {
        tooltip: {
            mode: "index",
            position: "nearest",
            displayColors: true,
            callbacks: {
                title: (tooltipItem) => Formatter.formatDate(tooltipItem[0].label, "DD/MM/YYYY"),
                label: (tooltipItem) => ` ${tooltipItem.dataset.label}: ${numeral(tooltipItem.formattedValue).format("0,0").replaceAll(",", " ")}`
            }
        }
    }
};

export const optionsLinesMonthly: ChartOptions<"line"> = {
    ...optionsLinesDaily,
    scales: {
        ...optionsLinesDaily.scales,
        x: {
            type: "time",
            time: {
                unit: "month",
                displayFormats: {
                    month: "MM/YYYY"
                }
            }
        }
    },
    plugins: {
        ...optionsLinesDaily.plugins,
        tooltip: {
            ...optionsLinesDaily.plugins!.tooltip,
            callbacks: {
                ...optionsLinesDaily.plugins!.tooltip!.callbacks,
                title: (tooltipItem) => Formatter.formatDate(tooltipItem[0].label, "MM/YYYY")
            }
        },
        legend: {
            display: false
        }
    }
};

export const optionsLinesMonthlyIncome: ChartOptions<"line"> = {
    ...optionsLinesMonthly,
    scales: {
        ...optionsLinesMonthly.scales,
        y: {
            ticks: {
                callback: (tickValue) => Intl.NumberFormat(Locale.getCurrentLocale() as string, {style: "currency", currency: Currency.EURO, maximumFractionDigits: 0}).format(tickValue as number)
            }
        }
    },
    plugins: {
        ...optionsLinesMonthly.plugins,
        tooltip: {
            ...optionsLinesMonthly.plugins!.tooltip,
            callbacks: {
                ...optionsLinesMonthly.plugins!.tooltip!.callbacks,
                label: (tooltipItem) => ` ${tooltipItem.dataset.label}: ${Intl.NumberFormat(Locale.getCurrentLocale() as string, {style: "currency", currency: Currency.EURO}).format(tooltipItem.raw as number)}`
            }
        }
    }
};
