export class Locale {
    static FRENCH = "fr";
    static ENGLISH = "en";

    private static currentLocale = navigator && (navigator.language || (navigator.languages && navigator.languages[0]) || Locale.FRENCH).toLowerCase().substr(0, 2);

    static values(): Locale[] {
        return [
            Locale.FRENCH,
            Locale.ENGLISH
        ];
    }

    static getCurrentLocale(): Locale {
        return this.values().find((it) => it === this.currentLocale) || Locale.ENGLISH;
    }

    static isFrench(): boolean {
        return this.getCurrentLocale() === this.FRENCH;
    }
}
